import React, { useState, useEffect } from 'react';
import styles from './checkReport.less';
import reportIcon from '../../../resource/checkReport/checkReport.png';
import { 
  Modal,
  Button,
  List,
  InputItem, 
  Toast } 
  from 'antd-mobile';
import { BaseUrl } from '../../../service';

CheckReport.displayName = 'CheckReport';
document.title = '咨询报告';
function CheckReport(){
  
  const alert = Modal.alert;
  const [time, setTime] = useState(60);
  const [isShowCode, setIsShowCode] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [showBindPhone, setShowBindPhone] = useState(false);
  const [mobile, setMobile] = useState('');
  const [code, setCode] = useState('');

  const { search = '?' } = window.location;
  const query = search.substring(1);
 
  const getCheckReportData = () =>{
    
    fetch(
     BaseUrl + `/open/v1/wc/report?${query}`,
      {
        method: 'GET',
      },
    )
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error('网络响应异常.');
    })
    .then(async (res) => {
      const result = await res.json();
      if(result.code !== 0){
        let errorMsg = '';
        switch (result.code){
          case 411: 
            Toast.info('输入手机号码,关联用户档案!')
            document.title = '绑定手机号';
            setShowBindPhone(true);
            setShowReport(false);
            break;
          case 412:
            errorMsg = '机构不存在';
            Toast.fail(errorMsg)
            break;
          case 414:
            document.title = '咨询报告';
            setShowReport(true);
            setShowBindPhone(false);
            break;
          default:
            errorMsg = '请求错误! ';
            Toast.fail(errorMsg)
        }
      }
      if(result.code === 0){
        const resultData = result.data || {},
              url = resultData?.url;
        window.location.href = url;
      }
    })
    .catch((err) => {
      Toast.fail('网络连接失败 !!!', 1);
    });
  };

  useEffect(() => {
    getCheckReportData();
  }, []);
  const onChange = (value) => {
    setMobile(value.replace(/\s/g, ''));
    if (value.replace(/\s/g, '').length < 11) {
      setHasError(true)
    } else {
      setHasError(false)
    }
  };
  const codeChange = (value) => {
    setCode(value.replace(/\s/g, ''));
  }
  const onErrorClick = () => {
    if (hasError) {
      Toast.info('请输入11位手机号码');
    } 
  };

  const sendVerification = () => {
    fetch(
      BaseUrl + `/api/sms/send.v2?country_code=86&mobile=${mobile}`,
      {
        method: 'GET',
      },
    )
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error('网络响应异常.');
    })
    .then(async (res) => {
      const result = await res.json();
      if (result.code !== 0) {
        let errorMsg = '';

        switch (result.code) {
          case 10001:
            errorMsg = '手机号码格式错误或者验证码无效';
            break;
          case 10004:
            errorMsg = '发送验证码无效';
            break;
          case 10007:
            errorMsg = '短信发送过于频繁';
            break;
          default:
            errorMsg = '请求错误！';
        }

        Toast.fail(errorMsg);
      }
    })
    .catch((err) => {
      Toast.fail('网络连接失败 !!!', 1);
    });
  };
  const countDown = () => {
    setIsShowCode(true);
    const active = setInterval(() => {
      setTime((preSecond) =>{
        if(preSecond <= 1){
          setIsShowCode(false);
          clearInterval(active);
          return 60;
        }
        return preSecond -1;
      })
    }, 1000)
    sendVerification();
  };
  const sendCode = () => {
    if(isShowCode) {
      return;
    }
    countDown();
  };
  const bindSubmit = () => {
    fetch(
      BaseUrl + `/open/v1/wc/bind?${query}&country_code=86&mobile=${mobile}&code=${code}`
    )
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error('网络响应异常.');
    })
    .then(async (res) => {
      const result = await res.json();
      if (result.code !== 0) {
        let errorMsg = '';
        switch (result.code) {
          case 10001:
            errorMsg = '手机号码格式错误或者验证码无效';
            break;
          case 411:
            errorMsg = '用户档案不存在';
            break;
          case 10007:
            errorMsg = '机构不存在';
            break;
          default:
            errorMsg = '验证码错误';
        }
        alert('绑定手机号失败', errorMsg, [
          { text: '返回'},
        ])
      }
      if(result.code === 0){
        getCheckReportData();
      }
    })
    .catch((err) => {
      Toast.fail('网络连接失败 !!!', 1);
    });
  }
  const handleSubmit = () => {
    if(!mobile){
      Toast.info('手机号码不能为空!');
      return;
    }
    if(!code){
      Toast.info('验证码不能为空!');
      return;
    }
    bindSubmit();
  };

  return (
    <>
      {
        showReport 
        &&
        (
          <div 
            className={styles.reportWrap}
          >
            <div 
              className={styles.emptyReport}
            >
                <img src={reportIcon} alt="" className={styles.imageEmptyReport} />
              <div 
                className={styles.emptyReportInfo}
              >
                <p>您目前没有收到诊断报告</p>
              </div>
            </div>
          </div>
        )
      }
      { 
        showBindPhone
        &&
        (
          <div 
            className={styles.checkReportWrap}
          >
            <div 
              className={styles.checkReport}
            >
              <div>
                <div 
                  className={styles.checkPhone}
                >
                  <List>
                    <InputItem
                      className={styles.phone}
                      type="phone"
                      placeholder="请输入手机号"
                      error={hasError}
                      onErrorClick={onErrorClick}
                      onChange={onChange}
                      extra={
                        <Button 
                          className={styles.verificationBtn}
                          size="small"
                          onClick={sendCode}
                        >
                          {
                            isShowCode
                            ? `${time}s`
                            : '获取验证码'
                          }
                        </Button>
                      }
                    >
                      手机号码
                    </InputItem>
                  </List>
                </div>
                <div>
                  <List>
                    <InputItem
                        className={styles.verificationCode}
                        type="number"
                        placeholder="请输入短信验证码"
                        onChange={codeChange}
                    >
                      验证码
                    </InputItem>
                  </List>
                  <Button 
                    className={styles.checkSubmit} 
                    type="primary"
                    onClick={handleSubmit}
                  >
                      确定
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )
      }
    </>
  )
}
export default CheckReport;
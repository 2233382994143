import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import DayReport from './pages/workbench/DayReport/DayReport';
import ConsultReport from './pages/workbench/ConsultReport/ConsultReport';
import CheckReport from './pages/workbench/CheckReport/CheckReport';
import SkinReport from './pages/workbench/SkinReport/SkinReport';
import SkinReportChart from './pages/workbench/SkinReport/SkinReportChart';
import './index.css';
import { KeepAlive, AliveScope } from 'react-activation';
import My3DImage from './pages/workbench/My3DImage/index'
import ZMHPDFReport from './pages/workbench/ZMHPDFReport';
import MEILAIWORDReport from './pages/workbench/MEILAIWORDReport';
import MEILAIReport from './pages/workbench/MEILAIReport';

const App = () => {
  return (
    <AliveScope>
      <Router>
        <Switch>
          <Route exact path="/day">
            <DayReport />
          </Route>
          <Route path="/consult">
            <ConsultReport />
          </Route>
          <Route path="/check">
            <CheckReport />
          </Route>
          <Route path="/skinReport">
            <KeepAlive>
              <SkinReport />
            </KeepAlive>
          </Route>
          <Route path="/skinReportChart/:id">
            <SkinReportChart />
          </Route>
          <Route path="/my3DImage/:id">
            <My3DImage />
          </Route>
          <Route path="/ZMHReport">
            <ZMHPDFReport />
          </Route>
          <Route path="/MLReport">
            <MEILAIWORDReport />
          </Route>
          <Route path="/MEILAIReport">
            <MEILAIReport />
          </Route>
        </Switch>
      </Router>
    </AliveScope>
  );
};

export default App;

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Space } from 'antd-mobile-v5';
import styles from './modelImage.less';
import { isEmpty, isFunction } from 'lodash';
import { PictureOutline } from 'antd-mobile-icons';
import classNames from 'classnames';
import { useDeepCompareEffect } from 'ahooks';

ModelImage.propTypes = {
  thumbnailData: PropTypes.array,
  changeMap: PropTypes.func,
  style: PropTypes.object,
};

ModelImage.displayName = 'ModelImage';

function ModelImage(props) {
  const { thumbnailData, changeMap, style, textureKey, isHistory, historyThumbnailData = [] } = props;

  const [isExpansion, setIsExpansion] = useState(false);
  const [activeThumbnail, setActiveThumbnail] = useState(false);

  const modelImageRef = useRef(null);

  useDeepCompareEffect(() => {
    if (isEmpty(thumbnailData) || isEmpty(textureKey)) return;
    setActiveThumbnail(textureKey.flag);
  }, [thumbnailData, textureKey]);

  const hasCurrThumbnail = (flag) => {
    if (isEmpty(historyThumbnailData) ||  !isHistory) return false;
    return !historyThumbnailData.map((htdItem) => htdItem.flag).includes(flag);
  }

  if (isEmpty(thumbnailData)) return null;

  return (
    <div
      ref={modelImageRef}
      className={classNames(styles.modeWrapper, {
        [styles.notExpansion]: !isExpansion,
      })}
      style={style}
    >
      {isExpansion && (
        <>
          <div className={styles.expansionUnderIcon} onClick={() => setIsExpansion(!isExpansion)}>
            》
          </div>
          <Space direction="vertical" size={5}>
            {(thumbnailData ?? [])?.map((value, index) => {
              return (
                <div
                  key={value.flag}
                  className={classNames(styles.imgWrapper, {
                    [styles.activeImgWrapper]: value.flag === activeThumbnail,
                    [styles.thumbnailWithMask]: hasCurrThumbnail(value.flag),
                  })}
                  onClick={() => {
                    setActiveThumbnail(value.flag);
                    isFunction(changeMap) && changeMap(value);
                  }}
                >
                  {value?.thumb_url ? (
                    <img src={value.thumb_url} />
                  ) : (
                    <div className={styles.imgIcon} data-layout-align="center center">
                      <PictureOutline fontSize={24} />
                    </div>
                  )}
                  <div className={styles.title}>{value?.title}</div>
                </div>
              );
            })}
          </Space>
        </>
      )}
      {!isExpansion && (
        <div className={styles.expansionUpIcon} onClick={() => setIsExpansion(!isExpansion)}>
          《
        </div>
      )}
    </div>
  );
}

export default ModelImage;

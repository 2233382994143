import React, { useState } from 'react';
import styles from "./CheckState.less";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import langCommon, { getLangFromLocal } from '../../../../utils/lang.util';

CheckState.displayName = "CheckState";

CheckState.propTypes = {
  images: PropTypes.array,
  label: PropTypes.string,
  flag: PropTypes.string,
  degree: PropTypes.string,
  level: PropTypes.number,
  description: PropTypes.string,
};

function CheckState(props) {
  const { images, label, flag, url, degree, level, description, downloadUrl, resultEnable } = props;

  const [lang] = useState(getLangFromLocal());

  const getHash = () => {
    const { search = "?" } = window.location;
    const query = search.substring(1);

    const params = query.endsWith("=") ? query.slice(0, query.length - 1) : query;
    return params;
  };

  return (
    <div className={styles.checkStateWrap}>
      <div className={styles.checkStateContent}>
        <div className={styles.checkStateLeft}>
          <Link
            to={{ pathname: `/skinReportChart/${getHash()}`, state: { flag, images, downloadUrl } }}
            className={styles.checkStateImage}
          >
            <img alt="" src={`${url}?imageMogr2/thumbnail/100x`} />
            <div className={styles.arrowIcon}>
              <svg t="1677903461466" className="icon" viewBox="0 0 1027 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="3770" width="1em" height="1em"  >
                <path d="M403.456 549.888l-173.568 173.568v-123.392c0-8.192-6.656-14.848-14.848-14.848H143.872c-8.192 0-14.848 6.656-14.848 14.848v237.056c0 31.744 26.112 57.344 57.344 57.344h237.568c8.192 0 14.848-6.656 14.848-14.848V808.96c0-8.192-6.656-14.848-14.848-14.848H302.08l173.056-173.056c5.632-5.632 5.632-14.848 0-20.48l-50.688-50.688c-6.144-5.632-15.36-5.632-20.992 0zM894.464 254.464V186.88c0.512-31.744-25.6-57.344-56.832-57.344h-237.568c-7.68 0-14.336 6.144-14.336 14.336v72.192c0 7.68 6.144 14.336 14.336 14.336h123.904l-169.984 168.96c-5.632 5.632-5.632 14.336 0 19.968L605.696 471.04c5.632 5.632 14.336 5.632 19.968 0L793.6 302.592v120.832c0 7.68 6.144 14.336 14.336 14.336h72.192c7.68 0 14.336-6.144 14.336-14.336v-168.96z" fill="#fff" p-id="3771"></path>
              </svg>
            </div>
          </Link>
        </div>
        <div className={styles.checkStateInfo}>
          <div className={styles.checkStateTitle}>
            <span className={styles.checkStateLabel}>{label}</span>
            {!!resultEnable && (
              <span className={styles[`level` + level]}>{degree}</span>
            )}
          </div>
          {description ? (
            <div className={styles.checkStateMessage}>{description}</div>
          ) : (
            <div className={styles.notMessage}>{langCommon.noDescription(lang)}</div>
          )}
        </div>
      </div>
    </div>
  );
}

export default CheckState;

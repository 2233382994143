import * as THREE from "three";
import { OBJLoader } from "three/examples/jsm/loaders/OBJLoader";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";

import { StereoEffectHoc } from "./StereoEffectHoc";

export const loaderObj = async (url, onProgress = () => {}) => {
  let loaderUrl = url;
  if (url instanceof Blob) {
    loaderUrl = URL.createObjectURL(url);
  }

  let result = null;
  const loader = new OBJLoader();
  try {
    result = await loader.loadAsync(loaderUrl, onProgress);
  } catch (error) {
    console.error(error);
  }
  if (url instanceof Blob) {
    URL.revokeObjectURL(loaderUrl);
  }
  return result;
};

export const loaderTexture = async (url, onProgress = () => {}) => {
  let loaderUrl = url;
  if (url instanceof Blob) {
    loaderUrl = URL.createObjectURL(url);
  }
  let result = null;
  const loader = new THREE.TextureLoader();
  try {
    result = await loader.loadAsync(loaderUrl, onProgress);
  } catch (error) {
    console.error(error);
  }
  if (url instanceof Blob) {
    URL.revokeObjectURL(loaderUrl);
  }
  return result;
};

export const ThreeInit = (id) => {
  const canvas = document.querySelector(`#${id}`);
  if (!canvas) return null;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const renderer = new THREE.WebGLRenderer({
    antialias: true, // 抗锯齿
    preserveDrawingBuffer: true,
  });
  // const eyesRenderer = new StereoEffectHoc(renderer);
  // eyesRenderer.setSize(window.innerWidth, window.innerHeight - 44, true);

  // renderer.setPixelRatio(window.devicePixelRatio);
  renderer.setPixelRatio(Math.max(window.devicePixelRatio, 2));
  renderer.setSize(width, height);
  renderer.setClearColor("#000");

  const eyesRenderer = new StereoEffectHoc(renderer, StereoEffectHoc.model.upAndDown);
  eyesRenderer.setSize(width, height, true);
  renderer.localClippingEnabled = true; //对象可以被剪切

  const fov = 45;
  const aspect = width / height; // the canvas default
  const near = 0.01;
  const far = 10000000000;
  const camera = new THREE.PerspectiveCamera(fov, aspect, near, far);
  camera.position.set(0, 1, 10);
  const controls = new OrbitControls(camera, canvas);
  controls.zoomSpeed = 0.5; // 摄像机缩放的速度，其默认值为1
  controls.rotateSpeed = 1; // 旋转的速度，其默认值为1
  controls.target.set(0, 5, 0);
  // controls.enableRotate = false;
  // controls.coupleCenters = false
  controls.update();

  const scene = new THREE.Scene();
  scene.background = new THREE.Color("black");

  const subScene = new THREE.Scene();
  subScene.background = new THREE.Color("black");
  canvas?.appendChild(renderer.domElement);
  renderer.render(scene, camera);
  return { scene, subScene, canvas, camera, controls, renderer, eyesRenderer };
};

export function frameArea(sizeToFitOnScreen, boxSize, boxCenter, camera, secondCamera) {
  const halfSizeToFitOnScreen = sizeToFitOnScreen * 0.5;
  const halfFovY = THREE.MathUtils.degToRad(camera.fov * 0.5);
  const distance = halfSizeToFitOnScreen / Math.tan(halfFovY);

  // compute a unit vector that points in the direction the camera is now
  // from the center of the box
  const direction = new THREE.Vector3().subVectors(camera.position, boxCenter).normalize();

  // move the camera to a position distance units way from the center
  // in whatever direction the camera was from the center already
  const cp = direction.multiplyScalar(distance).add(boxCenter);
  cp.z += 0.05;
  camera.position.copy(cp);
  // pick some near and far values for the frustum that
  // will contain the box.
  camera.near = boxSize / 100;
  camera.far = boxSize * 100;

  camera.updateProjectionMatrix();

  // point the camera to look at the center of the box
  camera.lookAt(boxCenter.x, boxCenter.y, boxCenter.z);
  if (secondCamera) {
    secondCamera.copy(camera);
    if (secondCamera.children.length > 1) {
      secondCamera.children = [secondCamera.children?.[0]];
    }
    secondCamera.updateProjectionMatrix();
  }
}

export function resizeRendererToDisplaySize(renderer) {
  const canvas = renderer.domElement;
  const width = canvas.clientWidth;
  const height = canvas.clientHeight;
  const needResize = canvas.width !== width || canvas.height !== height;
  if (needResize) {
    renderer.setSize(width, height, false);
  }
  return needResize;
}

export const createShaderMaterial = () => {
  return new THREE.ShaderMaterial({
    uniforms: {
      texture1: { type: "t", value: null },
      texture2: { type: "t", value: null },
      proportion: { value: 0.5 },
      mirror: { value: false },
    },
    transparent: true,
    vertexShader: `
    varying vec2 vUv;
    varying vec2 vPo;
    void main() {
      vUv = uv;
      vPo = position.xy;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
    }`,
    fragmentShader: `
    varying vec2 vUv;
    varying vec2 vPo;
    uniform sampler2D texture1;
    uniform sampler2D texture2;
    uniform float proportion;
    uniform bool mirror;
    void main() {
      vec4 Ca = texture2D(texture1, vUv);
      vec4 Cb = texture2D(texture2, vUv);
      if(mirror){
        if(vPo.x < proportion){
          gl_FragColor = Ca;
        }else{
          gl_FragColor = Cb;
        }
      }else{
        float alpha = (Cb.x+Cb.y+Cb.z)/3.0;
        Ca.xyz = (Cb.xyz) * alpha + Ca.xyz * (1.0 - alpha);
        gl_FragColor = Ca;
      }
    }`,
  });
};

export const disposeGroup = (scene) => {
  scene.traverse((c) => {
    if (c.isGroup) {
      c.traverse((g) => {
        if (g.isMesh) {
          g.geometry.dispose();
          g.material?.dispose();
          Object.values(g?.material?.uniforms ?? {}).map(({ value }) => {
            if (value?.isTexture) value.dispose();
          });
        }
      });
      c.removeFromParent();
    }
  });
};

export const copyGroupFromScene = (scene) => {
  const group = scene.children.find((c) => c.isGroup);
  if (!group) return null;
  const object = group.clone();
  object.traverse(function (subObj) {
    if (subObj.isMesh) {
      subObj.material = subObj.material.clone();
    }
  });
  return object;
};

export const copyGroup = (group) => {
  if (!group) return null;
  const object = group.clone();
  object.traverse(function (subObj) {
    if (subObj.isMesh) {
      subObj.material.dispose();
      Object.values(subObj.material.uniforms ?? {}).map(({ value }) => {
        if (value?.isTexture) value.dispose();
      });
      subObj.material = null;
      // subObj.material = subObj.material.clone();
    }
  });
  return object;
};

import React, { useEffect, useState, useCallback } from "react";
import { useLocation, withRouter } from "react-router-dom";
import styles from "./SkinReportChart.less";
import PrismaZoom from "react-prismazoom";
import SwipeableViews from "react-swipeable-views";
import { ProgressCircle } from "antd-mobile-v5";

import { useGetState, useAsyncEffect } from "ahooks";
import DB from "@/utils/db.js";
import { download, unpackZip ,blobToBase64} from "@/utils/file.js";

document.title = "";

function SkinReportChart(props) {
  const location = useLocation();
  const {
    match: { params },
  } = props;
  const { state: { flag = "", images = [], downloadUrl } = {}, pathname } = location;

  const [loading, setLoading] = useState(true);
  const [progressValue, setProgressValue] = useState(0);

  const [DBInstance, setDBInstance, getDBInstance] = useGetState();

  useEffect(() => {
    if (params?.id) {
      setDBInstance(new DB({ storeName: params?.id }));
    }
  }, [params]);

  const getDBKey = (info) => {
    return info?.filename?.split("/").pop();
  };
  const handleOnDownloadProgress = useCallback(({ flag, loaded, total, xhr }) => {
    if (pathname !== window.location.pathname) {
      xhr.abort();
    } else {
      setProgressValue(Math.floor((loaded / total) * 100));
    }
  }, []);

  const preload = useCallback(async () => {
    const info = await download({
      url: downloadUrl,
      flag: downloadUrl,
      onProgress: handleOnDownloadProgress,
    });
    if (info.status) {
      const unpackInfo = await unpackZip({ zipBlob: info.blob });
      await Promise.all(
        unpackInfo.map(async (item) => {
          const [key, blob] = Object.entries(item)?.[0];
          if (!blob) return;
          const base64Info = await blobToBase64(blob);
          if(base64Info.status){
            return await DBInstance.addItem(key, base64Info.texture);
          }
        })
      );
    }
  }, [DBInstance]);

  useAsyncEffect(async () => {
    if (DBInstance) {
      const key = getDBKey(images[0]);
      setLoading(true);
      const hasPreload = await DBInstance.hasKey(key);
      if (!hasPreload) {
        await preload();
      }
      setLoading(false);
    }
  }, [DBInstance]);

  const [chartItem, setChartItem] = useState(() => images.filter((item) => item.flag === flag));
  const [zoom, setZoom] = useState(1);
  const [zoomRefMap, setZoomRefMap] = useState({});
  const [index, setIndex] = useState(images.map((item) => item.flag).indexOf(flag));

  useEffect(() => {
    if (!location.state) {
      window.location.href = `/skinReport?${params?.id}`;
    }
  }, []);

  useEffect(() => {
    const [{ title = "" } = {}] = chartItem;
    document.title = title;
  }, [chartItem]);

  const switchChart = (flag) => {
    const currentIndex = images.map((item) => item.flag).indexOf(flag);
    setIndex(currentIndex);
    setChartItem(() => images.filter((item) => item.flag === flag));
  };

  const [imageList, setImageList] = useState([]);
  const modifyImageList = async (images) => {
    const newList = await Promise.all(
      images.map(async (item) => {
        const key = getDBKey(item);
        const url = await getDBInstance().getItem(key);
        return { ...item, url };
      })
    );
    setImageList(newList);
  };

  useEffect(() => {
    if (!loading) {
      modifyImageList(images);
    }
  }, [loading]);

  return (
    <div className={styles.skinReportChartWrap}>
      <div>
        <SwipeableViews
          index={index}
          disabled={!(zoom === 1)}
          onChangeIndex={(index) => setChartItem(() => [images[index]])}
        >
          {imageList.map((item) => {
            return (
              <div key={item.flag}>
                <PrismaZoom
                  ref={(ref) => {
                    if (ref && !zoomRefMap[item.flag]) {
                      setZoomRefMap((oldRef) => ({
                        ...oldRef,
                        [item.flag]: ref,
                      }));
                    }
                  }}
                  onZoomChange={(e) => {
                    setZoom(e);
                  }}
                >
                  <div className={styles.imgBox}>
                    <img src={item.url} alt="" />
                  </div>
                </PrismaZoom>
              </div>
            );
          })}
        </SwipeableViews>
      </div>

      {!loading &&
        chartItem.map((item) => {
          return (
            <div key={item.flag} className={styles.skinReportChartDesc}>
              {item.detail}
            </div>
          );
        })}
      <div className={styles.skinReportChartTab}>
        <ul>
          {imageList.map((item, index) => {
            return (
              <li
                key={index}
                onClick={() => {
                  zoomRefMap?.[chartItem?.[0].flag].reset();
                  switchChart(item.flag);
                }}
              >
                <img src={item.url} alt="" />
              </li>
            );
          })}
        </ul>
      </div>
      {loading && (
        <div className={styles.loading}>
          <ProgressCircle percent={progressValue}>{progressValue}%</ProgressCircle>
        </div>
      )}
    </div>
  );
}

export default withRouter(SkinReportChart);

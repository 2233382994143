import React, { useCallback, useRef, useLayoutEffect, useState, useEffect } from 'react';
import * as THREE from 'three';
import { useGetState, useAsyncEffect, useDebounceEffect, useUpdateEffect } from 'ahooks';
import classNames from 'classnames';
import { ProgressBar, Empty, DotLoading ,Toast} from 'antd-mobile-v5';
import { ExclamationCircleOutline } from 'antd-mobile-icons'
import { useLocation, withRouter } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Draggable from 'react-draggable';
import {
  ThreeInit,
  loaderObj,
  frameArea,
  loaderTexture,
  resizeRendererToDisplaySize,
  createShaderMaterial,
  disposeGroup,
  copyGroup,
} from '@/utils/Threejs/threeUtils';
import DB, { DBInstanceTableName } from '@/utils/Threejs/db.js';
import {
  download,
  unpackZipByPako,
  blobToArrayBuffer,
  blobToBase64,
} from '@/utils/Threejs/file.js';
import { getSkinReportData } from '@/service/skinReport.api.js';
import ModelImage from './components/ModelImage/modelImage';
import HistoryTime from './components/HistoryTime/HistoryTime';

import grayIcon from '../../../resource/skinReport/gray_icon.png';
import normalIcon from '../../../resource/skinReport/normal_icon.pic';
import sun from '../../../resource/skinReport/sun.jpg';
import styles from './index.less';
import { getSkinList } from '../../../service/skinReport.api';
import { autoDeleteIndexDB } from '@/utils/Threejs/db';
import qs from 'qs';
import langCommon, { getLangFromLocal } from '../../../utils/lang.util';

const defaultConfig = {
  DBKey: '',
  objectKey: '',
  textureKey: '',
  subTextureKey: '',
  proportion: 0,
  mirror: false,
  unShow: false, // 灰度
  rotation: { x: 0, y: 0, force: false }, // 旋转
};
let rId = null;
// 从接口返回数据获取下载地址map
const getDownloadUrlMapFromData = (data) => {
  if (!data) return {};
  const { zips } = data;
  return Object.values(zips ?? {}).reduce((r, item) => {
    return { ...r, [item.flag]: item.url };
  }, {});
};

// 根据缓存Key获取map
const getMappingInfoByCacheKey = (imageData, cacheKey) => {
  if (!cacheKey) return null;
  const { mappings } = imageData?.three_dimensional ?? {};
  return mappings.find((info) => {
    const { filename } = info;
    return filename.endsWith(cacheKey);
  });
};

const getImageInfoByFlag = (mappings, flag) => {
  return mappings?.find((item) => item.flag === flag);
};
const getImageInfoByUrl = (mappings, url) => {
  return mappings?.find((item) => item.url === url);
};
const getCacheKeyFromImageInfo = (imageInfo) => {
  if (typeof imageInfo?.filename !== 'string') return '';
  return imageInfo?.filename?.split('/').pop();
};
// 缩略图缓存key
const getThumbCacheKeyFromImageInfo = (imageInfo) => {
  if (typeof imageInfo?.thumb_filename !== 'string') return '';
  return imageInfo?.thumb_filename?.split('/').pop();
};

const My3DImage = (props) => {
  const [lang] = useState(getLangFromLocal());

  useEffect(() => {
    document.title = langCommon.my3DImage(lang);
  }, []);

  const location = useLocation();
  const {
    match: { params },
  } = props;

  const { state: { data = {}, isRawDataNull } = {}, pathname } = location;
  const [currentSkinData, setCurrentSkinData, getCurrentSkinData] = useGetState({});
  const [queryParams, setQueryParams] = useState(null);
  const [, setDBInstanceMap, getDBInstanceMap] = useGetState();
  const [threeInfoMap, setThreeInfoMap, getThreeInfoMap] = useGetState();
  const [, setCurrentMiddleValue, getCurrentMiddleValue] = useGetState();
  const [type, setType] = useState('img');
  const [position, setPosition] = useState({ x: 167, y: 158 });
  const [objLoading, setObjLoading] = useGetState(false);

  const [isHistory, setIsHistory] = useState(false);
  const [historyInfo, setHistoryInfo, getHistoryInfo] = useGetState();

  const [mainConfig, setMainConfig, getMainConfig] = useGetState(defaultConfig);
  const [subConfig, setSubConfig] = useGetState(defaultConfig);

  const [mainLoading, setMainLoading] = useGetState(true);
  const [historyDownloadLoading, setHistoryDownloadLoading] = useGetState(false);
  const [skinListState, setSkinListState] = useGetState([]);
  const [loadProgressMap, setLoadProgressMap] = useState({}); // 下载进度信息

  const [historySelectInfo, setHistorySelectInfo] = useGetState({ current: null, history: null }); // 历史对比选择数据
  const [skinInfoList, setSkinInfoList, getSkinInfoList] = useGetState({}); // skin 数据存储 ,key为hash

  useEffect(() => {
    setCurrentSkinData(data);
    setHistorySelectInfo(pre => ({ ...pre, current: data.hash }));
    setSkinInfoList(pre => ({ ...pre, [data.hash]: data }));
  }, [data]);

  useEffect(() => {
    const query = qs.parse(params.id, {
      ignoreQueryPrefix: true,
    });

    const queryKeys = Object.keys(query);
    let newParams;
    if (queryKeys.includes('hash')) {
      newParams = query;
    } else {
      newParams = {
        hash: queryKeys[0],
      };
    }

    setQueryParams(newParams);
  }, []);

  useEffect(() => {
    if (skinListState.length) {
      const currentIndex = skinListState.findIndex((item) => item.hash === getCurrentSkinData().hash);
      const nextSkinInfo = skinListState[currentIndex + 1];
      let historyHash = nextSkinInfo?.hash;
      if (isEmpty(nextSkinInfo)) {
        historyHash = getCurrentSkinData().hash;
        setHistoryInfo(getCurrentSkinData());
      }
      setHistorySelectInfo(pre => ({ ...pre, history: historyHash }));
    }
  }, [skinListState]);

  const getSkinListFetch = async (value) => {
    const [status, result] = await getSkinList(value);
    if (status) {
      setSkinListState(result);
    }
  };

  const needRenderRef = useRef(true);
  const THREERender = useCallback(() => {
    if (needRenderRef.current) {
      needRenderRef.current = false;
      const { scene, subScene, renderer, eyesRenderer, camera } = getThreeInfoMap();
      if (resizeRendererToDisplaySize(renderer)) {
        const parent = renderer.domElement.parentElement;
        const width = parent.clientWidth;
        const height = parent.clientHeight;
        camera.aspect = width / height;
        camera.updateProjectionMatrix();
        renderer.setSize(width, height, true);
        renderer.setScissor(0, 0, width, height);
      }
      if (['history', 'split'].includes(getCurrentMiddleValue())) {
        eyesRenderer.setEyeSeparation(0);
        eyesRenderer.render(subScene, scene, camera);
      } else {
        renderer.render(scene, camera);
      }
    }

    if (rId) window.cancelAnimationFrame(rId);
    if (window.location.pathname.includes('my3DImage')) {
      rId = requestAnimationFrame(THREERender);
    }
  }, []);

  useEffect(() => {
    if (threeInfoMap) {
      rId = requestAnimationFrame(THREERender);
    }
    return () => {
      if (rId) {
        window.cancelAnimationFrame(rId);
      }
    };
  }, [threeInfoMap, THREERender]);

  // 根据ID获取indexDb
  const getDBInstance = (id) => {
    if (!id) return null;
    const db = getDBInstanceMap()?.[id];
    if (db) return db;
    const newDB = new DB({ storeName: id });
    setDBInstanceMap((map) => ({ ...(map ?? {}), [id]: newDB }));
    return newDB;
  };

  // 下载进度
  const handleOnDownloadProgress = ({ flag: item, loaded, total, xhr }) => {
    const { id, flag } = item;
    if (pathname !== window.location.pathname) {
      xhr.abort();
    } else if (id) {
      setLoadProgressMap((pre) => ({
        ...pre,
        [id]: {
          ...(pre?.[id] ?? {}),
          [flag]: (loaded / total) * 100,
        },
      }));
    }
  };

  // 下载资源
  const downloadResource = async (zipUrlMap, dataID) => {
    // 下载压缩包
    const zipBlobList = await Promise.all(
      Object.entries(zipUrlMap).map(async ([flag, url]) => {
        const downloadParams = {
          url,
          flag: { id: dataID, flag },
          onProgress: handleOnDownloadProgress,
        };
        return await download(downloadParams);
      }),
    );
    // 解压压缩包
    const zipBuffer = await Promise.all(zipBlobList.map(({ blob }) => blobToArrayBuffer(blob)));
    const unpackInfoList = await Promise.all(
      zipBuffer.map(async ({ texture }) => {
        return await unpackZipByPako({
          zipBlob: texture,
        });
      }),
    );
    // blob存储到indexdb
    const DBInstance = getDBInstance(dataID);
    const dbItems = unpackInfoList.flat(Infinity).map((unpackInfo) => {
      const [key, blob] = Object.entries(unpackInfo).pop();
      return { key: key, value: blob };
    });
    await DBInstance.addItems(dbItems);
    // 数据库名存入到名为 DBInstanceTableName 的表中
    const instanceCountDB = new DB({ storeName: DBInstanceTableName });
    instanceCountDB.addItem(dataID, true);
    return true;
  };

  const controlsChange = () => {
    needRenderRef.current = true;
  };

  // 初始化Threejs
  useEffect(() => {
    const threeInfo = ThreeInit('My3DImage');
    const { controls } = threeInfo ?? {};
    setThreeInfoMap(threeInfo);
    controls.addEventListener('change', controlsChange);
    return () => {
      if (!isEmpty(getThreeInfoMap())) {
        // 手动释放threejs内存
        const { renderer, scene, subScene, controls } = getThreeInfoMap();
        controls.removeEventListener('change', controlsChange);
        disposeGroup(scene);
        disposeGroup(subScene);
        scene?.clear();
        subScene?.clear();
        renderer?.dispose();
        renderer.forceContextLoss();
        renderer.content = null;

        let gl = renderer.domElement.getContext('webgl');
        gl && gl.getExtension('WEBGL_lose_context').loseContext();
      }
    };
  }, []);

  // 转换坐标
  const transformCord = ({ x, y }) => {
    const { scene, renderer } = getThreeInfoMap() ?? {};
    if (!scene) return { x: 0, y: 0 };
    const parent = renderer.domElement.parentElement;
    const width = parent.clientWidth;
    const height = parent.clientHeight;
    const x1 = (x / width) * 2 - 1;
    const y1 = -(y / height) * 2 + 1;
    return { x: x1, y: y1 };
  };

  // 初始化灯光
  const renderLight = useCallback(() => {
    if (!threeInfoMap) return;
    const { scene, subScene } = threeInfoMap;
    scene?.children?.filter((c) => c.type !== 'Group').map((c) => scene.remove(c));
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);
    const spotLight = new THREE.SpotLight(0xaaaaaa, 1.35, 120);
    const calcPosition = transformCord(position);
    spotLight.position.set(calcPosition.x, calcPosition.y, 0.7);
    spotLight.visible = false;
    scene.add(spotLight);
    subScene.add(ambientLight.clone());
    subScene.add(spotLight.clone());
  }, [threeInfoMap]);

  useEffect(() => {
    const { scene, subScene } = getThreeInfoMap() ?? {};
    const spotLight = scene?.children?.find((c) => c.isSpotLight);
    const subSpotLight = subScene?.children?.find((c) => c.isSpotLight);
    const calcPosition = transformCord(position);
    if (!isEmpty(spotLight)) {
      spotLight.position.set(calcPosition.x, calcPosition.y, spotLight.position.z);
    }
    if (!isEmpty(subSpotLight)) {
      subSpotLight.position.set(calcPosition.x, calcPosition.y, subSpotLight.position.z);
    }
    needRenderRef.current = true;
  }, [position]);

  useEffect(() => {
    if (threeInfoMap) renderLight();
  }, [threeInfoMap]);

  // 首次设置mainConfig
  useAsyncEffect(async () => {
    await autoDeleteIndexDB();

    if (threeInfoMap && !isEmpty(currentSkinData)) {
      const DBInstance = getDBInstance(getCurrentSkinData().id);
      const urlMap = getDownloadUrlMapFromData(getCurrentSkinData());
      let resourcesStatus = true;
      setMainLoading(true);

      const objKey = getCurrentSkinData()?.three_dimensional?.obj?.split('/').pop();
      const hasKey = await DBInstance.hasKey(objKey);
      if (!hasKey) {
        resourcesStatus = await downloadResource(urlMap, getCurrentSkinData().id);
      }
      setMainLoading(false);
      if (resourcesStatus) {
        const defaultImageInfo = getImageInfoByUrl(
          getCurrentSkinData()?.three_dimensional?.mappings,
          getCurrentSkinData()?.three_dimensional?.default,
        );
        const textureKey = getCacheKeyFromImageInfo(defaultImageInfo ?? getCurrentSkinData()?.three_dimensional?.mappings?.[0]);
        setMainConfig((pre) => ({
          ...defaultConfig,
          ...pre,
          DBKey: getCurrentSkinData().id,
          objectKey: objKey,
          textureKey: pre.textureKey ? pre.textureKey : textureKey,
        }));
      }
    }
  }, [threeInfoMap, currentSkinData]);

  // 更新灯光
  const updateLight = (currentScene, unShow = false) => {
    const { camera } = getThreeInfoMap();
    if (currentScene) {
      const currentGroup = currentScene?.children?.find((c) => c.isGroup);
      const color = unShow ? 0x000000 : 0xffffff;
      const ambientLightColor = new THREE.Color(color);
      const ambientLightIntensity = unShow ? 0.3 : 1;
      const ambientLight = currentScene.children?.find((c) => c.isAmbientLight);
      ambientLight.color = ambientLightColor;
      ambientLight.intensity = ambientLightIntensity;

      const spotLight = currentScene.children?.find((c) => c.isSpotLight);

      spotLight.shadow.camera.near = camera.near;
      spotLight.shadow.camera.fov = camera.fov;
      spotLight.shadow.camera.fov = camera.fov;
      if (currentGroup) spotLight.target = currentGroup;
      spotLight.visible = unShow;
    }
  };

  const [_objLoadingProgress, setObjLoadingProgress] = useGetState(0);
  // 更新threejs 模型和纹理
  const updateThreeInfo = async (config, type = 'main') => {
    const { scene, subScene, camera, controls } = getThreeInfoMap() ?? {};
    const currentScene = type === 'main' ? scene : subScene;
    if (!currentScene) return;
    const {
      DBKey,
      objectKey,
      textureKey,
      subTextureKey,
      proportion,
      mirror,
      unShow = false,
      rotation,
    } = config;
    if (!DBKey || !objectKey) return disposeGroup(currentScene);
    const DBInstance = getDBInstance(DBKey);
    const groupSame = currentScene?.children?.find((c) => c.isGroup && c.userData?.key === DBKey);

    let group = groupSame;
    if (!groupSame) {
      const groupFromMain = scene?.children?.find((c) => c.isGroup && c.userData?.key === DBKey);
      if (groupFromMain) {
        group = copyGroup(groupFromMain);
      } else {
        const blob = await DBInstance.getItem(objectKey);
        setObjLoading(true);
        setObjLoadingProgress(0);
        group = await loaderObj(blob, (loadingParams) => {
          const progress = (loadingParams.loaded / loadingParams.total) * 100;
          setObjLoadingProgress(progress.toFixed(1));
          if (loadingParams.loaded === loadingParams.total) {
            // setObjLoading(false);
          }
        });
        setObjLoading(false);
        // setMainLoading(false);
        group.userData.key = DBKey;
        // 镜像
        group.applyMatrix4(new THREE.Matrix4().makeScale(-1, 1, 1));
      }
      group.visible = false;
      if (type === 'main' && controls.maxDistance === Infinity) {
        const box = new THREE.Box3().setFromObject(group);
        const boxSize = box.getSize(new THREE.Vector3()).length();
        const boxCenter = box.getCenter(new THREE.Vector3());
        frameArea(boxSize * 0.9, boxSize, boxCenter, camera);
        controls.maxDistance = boxSize * 10;
        controls.minDistance = boxSize / 4;
        controls.target.copy(boxCenter);
        controls.saveState();
        controls.update();
      }
      // 切换模型，销毁上次模型
      disposeGroup(currentScene);
      currentScene.add(group);
    }
    // 旋转
    const X = rotation?.x ?? 0;
    const Y = rotation?.y ?? 0;
    let controlsReset = false;
    if (X !== group.rotation.x || Y !== group.rotation.y || rotation?.force) {
      group.rotation.x = rotation?.x ?? 0;
      group.rotation.y = rotation?.y ?? 0;
      controlsReset = true;
    }

    // 更换材质
    const meshs = group?.children?.filter((m) => m.isMesh);
    meshs.map((m) => (m.visible = false));
    const correctMaterialType = unShow ? 'MeshStandardMaterial' : 'ShaderMaterial';
    let mesh = meshs.find((m) => m?.material?.type === correctMaterialType);
    let material = mesh?.material;
    if (!mesh) {
      if (correctMaterialType === 'ShaderMaterial') {
        material = createShaderMaterial();
      } else {
        material = new THREE.MeshStandardMaterial({ color: 0xffffff, metalness: 0, roughness: 1 });
      }
      mesh = meshs[0]?.clone();
      mesh.material?.map?.dispose();
      mesh.material?.dispose();
      mesh.material = material;
      mesh.material.needsUpdate = true;
      group.add(mesh);
    }
    mesh.visible = true;

    if (!unShow && textureKey !== material?.uniforms?.texture1?.value?.userData?.key) {
      // 更换纹理
      let texture = null;
      if (textureKey) {
        const blob = await DBInstance.getItem(textureKey);
        texture = await loaderTexture(blob);
        if (texture) texture.userData.key = textureKey;
      }
      material.uniforms.texture1.value?.dispose();
      material.uniforms.texture1.value = texture;
    }
    if (!unShow && subTextureKey !== material?.uniforms?.texture2?.value?.userData?.key) {
      // 更换纹理
      let texture = null;
      if (subTextureKey) {
        const blob = await DBInstance.getItem(subTextureKey);
        texture = await loaderTexture(blob);
        if (texture) texture.userData.key = subTextureKey;
      }
      material.uniforms.texture2.value?.dispose();
      material.uniforms.texture2.value = texture;
    }
    if (!unShow) {
      material.uniforms.mirror.value = mirror;
      material.uniforms.proportion.value = proportion;
      material.uniformsNeedUpdate = true;
    }
    group.visible = true;
    if (controlsReset) controls.reset();
    if (rotation?.force) {
      // 重置强制旋转
      const setConfig = type === 'main' ? setMainConfig : setSubConfig;
      setConfig((pre) => ({ ...pre, rotation: { ...pre.rotation, force: false } }));
    }
    updateLight(currentScene, config.unShow);
    needRenderRef.current = true;
  };

  useDebounceEffect(
    () => {
      updateThreeInfo(mainConfig);
    },
    [mainConfig],
    { wait: 100 },
  );

  useDebounceEffect(
    () => {
      updateThreeInfo(subConfig, 'sub');
    },
    [subConfig],
    { wait: 100 },
  );

  const getHistoryInfoFetch = async (value) => {
    const [status, result] = await getSkinReportData(value);
    if (status) {
      setHistoryInfo(result);
    }
  };

  // 历史对比
  useAsyncEffect(async () => {
    if (historyInfo) {
      const DBInstance = getDBInstance(historyInfo.id);
      const objKey = getCurrentSkinData()?.three_dimensional?.obj?.split('/').pop();
      const hasKey = await DBInstance.hasKey(objKey);
      let resourcesStatus = true;

      if (!hasKey) {
        setHistoryDownloadLoading(true);
        const urlMap = getDownloadUrlMapFromData(historyInfo);
        resourcesStatus = await downloadResource(urlMap, historyInfo.id);
        setHistoryDownloadLoading(false);

      }
      if (resourcesStatus) {
        const { textureKey: mainTextureKey } = getMainConfig();
        const mainInfo = getMappingInfoByCacheKey(getCurrentSkinData(),mainTextureKey);
        let info = getImageInfoByFlag(historyInfo?.three_dimensional?.mappings,mainInfo.flag);
        if (!info) {
          info = getImageInfoByUrl(
            historyInfo?.three_dimensional?.mappings,
            historyInfo?.three_dimensional?.default,
          );
          info = info ?? historyInfo?.three_dimensional?.mappings?.[0];
        }
        const textureKey = getCacheKeyFromImageInfo(info);
        if (textureKey) {
          setSubConfig((pre) => ({
            ...defaultConfig,
            ...pre,
            DBKey: historyInfo?.id,
            objectKey: objKey,
            textureKey,
          }));
        }
      }
    }
  }, [historyInfo?.id]);

  // 切换灰度
  const handleOnSwitchModel = useCallback(() => {
    const unShow = type === 'img';
    setMainConfig((pre) => ({ ...pre, unShow }));
    setSubConfig((pre) => ({ ...pre, unShow }));
    setType(unShow ? 'model' : 'img');
  }, [type]);

  // 获取缩略图base64
  const [thumbnailList, setThumbnailList] = useState([]);
  useAsyncEffect(async () => {
    // data?.three_dimensional?.mappings
    if (!mainLoading) {
      const DBInstance = getDBInstance(getCurrentSkinData().id);
      const base64List = await Promise.all(
        getCurrentSkinData()?.three_dimensional?.mappings?.map(async (item) => {
          const cacheKey = getThumbCacheKeyFromImageInfo(item);
          const blob = await DBInstance.getItem(cacheKey);
          const { status, texture } = await blobToBase64(blob);
          return { ...item, thumb_url: status ? texture : null };
        }),
      );
      setThumbnailList(base64List);
    }
  }, [mainLoading]);

  useLayoutEffect(() => {
    // 如果是从3d页面分享点进来
    if (isEmpty(getCurrentSkinData()) && !isRawDataNull) {
      window.location.href = `/skinReport?${params?.id}`;
    }
  }, []);

  useEffect(() => {
    const { scene, camera, controls } = getThreeInfoMap() ?? {};
    if (!controls || !camera) return;
    controls.reset();
    if (isHistory) {
      const group = scene?.children?.find((c) => c.isGroup);
      const box = new THREE.Box3().setFromObject(group);
      const boxSize = box.getSize(new THREE.Vector3()).length();
      const boxCenter = box.getCenter(new THREE.Vector3());
      frameArea(boxSize * 0.65, boxSize, boxCenter, camera);
    }
  }, [isHistory]);

  useUpdateEffect(() => {
    if (queryParams?.enable_history === '1') {
      getSkinListFetch(queryParams.hash);
    }
  }, [queryParams]);

  // 切换贴图
  const handleChangeMap = async (info) => {
    setType('img');
    const cacheKey = getCacheKeyFromImageInfo(info);
    const historyDetail = getHistoryInfo();
    if (historyDetail && getCurrentMiddleValue() === 'history') {
      // 历史数据存在时且在历史对比
      const historyMap = getImageInfoByFlag(historyDetail?.three_dimensional?.mappings, info.flag);
      if(!historyMap) return Toast.show({
        content: langCommon.historyReportPrompt(lang),
        icon:<ExclamationCircleOutline />
      } )
      const historyCacheKey = getCacheKeyFromImageInfo(historyMap);
      setMainConfig((pre) => ({ ...pre, unShow: false, textureKey: cacheKey }));
      setSubConfig((pre) => ({ ...pre, unShow: false, textureKey: historyCacheKey }));
    }else{
      setMainConfig((pre) => ({ ...pre, unShow: false, textureKey: cacheKey }));
      setSubConfig((pre) => ({ ...pre, unShow: false }));
    }
  };

  const getZipLoadingPro = useCallback(
    (id) => {
      const progressMap = loadProgressMap[id];
      const progressList = Object.values(progressMap ?? {});
      if (!progressList.length) return 0;
      const loaded = progressList.reduce((loaded, progress) => loaded + progress, 0);
      return (loaded / progressList.length).toFixed(1);
    },
    [loadProgressMap],
  );

  const getHistorySkinInfo = async (historyHash) => {

    if (!getSkinInfoList[historyHash]) {
      const [status, result] = await getSkinReportData(historyHash);
      if (status) {
        setHistoryInfo(result);
        setSkinInfoList(pre => ({ ...pre, [result.hash]: result }));
      }
    }
    // setHistorySelectInfo({ current: currentHash, history: historyHash });
    setHistorySelectInfo(pre => ({ ...pre, history: historyHash }));
  };

  const getCurrentSkinInfo = async (currentHash) => {
    if (!getSkinInfoList[currentHash]) {
      const [status, result] = await getSkinReportData(currentHash);
      if (status) {
        setCurrentSkinData(result);
        setSkinInfoList(pre => ({ ...pre, [result.hash]: result }));
      }
    }
    setHistorySelectInfo(pre => ({ ...pre, current: currentHash }));
  };

  useEffect(() => {
    // 历史对比切换回正常展示时，currentSkinData切换回props数据
    if (!isHistory) {
      setCurrentSkinData(data);
    }

  }, [isHistory]);

  // 当前纹理的换成key
  const getCurrentTextureKey = useCallback((data, textureKey) => {
    const key = getMappingInfoByCacheKey(data, textureKey);
    return key;
  }, []);

  return (
    <div className={styles.my3DImageWrapper}>
      {!isEmpty(data) ? (
        <>
          {/*历史对比*/}
          {queryParams?.enable_history === '1' && (
            <div
              className={classNames(styles.historyContrastWrapper, {
                [styles.active]: isHistory,
              })}
              onClick={() => {
                setIsHistory(!isHistory);

                if (!skinInfoList[historySelectInfo.history]) {
                  getHistoryInfoFetch(historySelectInfo.history);

                }

                if (historySelectInfo.current !== currentSkinData.hash) {
                  setCurrentSkinData(skinInfoList[historySelectInfo.current]);
                }

                setCurrentMiddleValue(isHistory ? 'normal' : 'history');
                needRenderRef.current = true;
              }}
            >
              {langCommon.historyCompare(lang)}
            </div>
          )}
          {isHistory && (
            <>
              <HistoryTime
                className={styles.historyUp}
                showModal
                skinList={skinListState}
                disableKey={historySelectInfo.current}
                selected={[historySelectInfo.history, historySelectInfo.current].filter(Boolean)}
                onOk={(value) => {
                  if (value !== historySelectInfo.history) {
                    getHistorySkinInfo(value);
                  }
                }}
              />
              <HistoryTime
                className={styles.historyUnder}
                showModal
                skinList={skinListState}
                disableKey={historySelectInfo.history}
                selected={[historySelectInfo.current, historySelectInfo.history].filter(Boolean)}
                onOk={(value) => {
                  if (value !== historySelectInfo.current) {
                    getCurrentSkinInfo(value);
                  }
                }}
              />
            </>
          )}
          {/*模式图*/}
          <ModelImage
            thumbnailData={thumbnailList}
            changeMap={handleChangeMap}
            textureKey={getCurrentTextureKey(currentSkinData, mainConfig.textureKey)}
            historyThumbnailData={getHistoryInfo()?.three_dimensional?.mappings}
            isHistory={isHistory}
          />
          {!!(currentSkinData?.shape_analyze) && (
            <div className={styles.switchModelWrapper} onClick={handleOnSwitchModel}>
              <img src={type === 'model' ? normalIcon : grayIcon} alt="" />
              <span className={classNames({ [styles.label]: type === 'model' })}>{langCommon.gray(lang)}</span>
            </div>
          )}
          {/*// 灯光*/}
          {type === 'model' && (
            <Draggable
              axis="x"
              handle=".handle"
              bounds="parent" // 指定移动边界为父元素
              position={{ x: position.x, y: position.y }}
              grid={[25, 25]}
              scale={1}
              onDrag={(e, data) => {
                setPosition(data);
              }}
            >
              <img
                className="handle"
                src={sun} width={80} height={80}
                style={{
                  position: 'absolute'
                }}
              />
            </Draggable>
          )}
          {objLoading && (
            <div className={styles.objLoading}>
              <DotLoading color="primary" style={{ fontSize: '25px' }} />
            </div>
          )}
          {(mainLoading || historyDownloadLoading) && (
            <div className={styles.loading}>
              <ProgressBar
                percent={getZipLoadingPro(mainLoading ? getCurrentSkinData().id : historyInfo?.id)}
                style={{
                  '--track-width': '3px',
                  width: '156px',
                }}
              />
            </div>
          )}
          {/*3DImage*/}
          <div id="My3DImage" className={styles.three}></div>
        </>
      ) : (
        <div className={styles.emptyWrapper}>
          <Empty description={langCommon.no3DImage(lang)} />
        </div>
      )}
    </div>
  );
};

export default withRouter(My3DImage);

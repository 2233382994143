import React, { useEffect, useState } from 'react';
import * as DocxPreview from 'docx-preview';
import {
  Document,
  Packer,
  Paragraph,
  ImageRun,
  AlignmentType,
  TextRun,
  PageBreak,
} from 'docx';
import { Button, Toast } from 'antd-mobile';
import { saveAs } from 'file-saver';
import { imageToBlob } from '../../../utils/file';
import { getConsultationReport } from '../../../service/mlWordReport.api';
import qs from 'qs';
import { useUpdateEffect } from 'ahooks';
import {
  download,
  unpackZipByPako,
  blobToArrayBuffer,
} from '@/utils/Threejs/file.js';
import styles from './index.less';
import { DotLoading } from 'antd-mobile-v5';
import { isEmpty } from 'lodash';

Index.propTypes = {};

Index.displayName = 'Index';

const generateSpace = (num) => {
  return Array(num).fill(' ').join('');
};

function Index() {

  const [reportResult, setReportResult] = useState(null);
  const [mappingList, setMappingList] = useState(null);
  const [mappingImageBlobList, setMappingImageBlobList] = useState(null);
  const [loading, setLoading] = useState(true);

  // 下载资源
  const downloadResource = async (zipUrlMap, dataID, images) => {
    // 下载压缩包
    const zipBlobList = await Promise.all(
      Object.entries(zipUrlMap).map(async ([flag, url]) => {
        const downloadParams = {
          url,
          flag: { id: dataID, flag },
        };
        return await download(downloadParams);
      }),
    );
    // 解压压缩包
    const zipBuffer = await Promise.all(zipBlobList.map(({ blob }) => blobToArrayBuffer(blob)));
    const unpackInfoList = await Promise.all(
      zipBuffer.map(async ({ texture }) => {
        return await unpackZipByPako({
          zipBlob: texture,
        });
      }),
    );
    const filterResult = unpackInfoList.flat(Infinity).map((unpackInfo) => {
      const [key, blob] = Object.entries(unpackInfo).pop();
      if (key.endsWith('M.jpg')) return;
      const title = images.find((findItem) => findItem.filename.includes(key))?.title;
      if (isEmpty(title)) return;
      return { key: key, value: blob, title: title };

    }).filter(Boolean);

    setMappingList(filterResult);

    return true;
  };

  const getConsultationReportFetch = async (value) => {
    const [status, result] = await getConsultationReport(value);
    if (status) {
      const { zips } = result?.skin ?? {};
      const urlMap = {
        [zips.thumb.flag]: [zips.thumb.url],
      };

      let resourcesStatus = true;
      resourcesStatus = await downloadResource(urlMap, result?.info?.id, result?.skin?.images);
      if (!resourcesStatus) {
        Toast.fail('数据下载失败！请刷新后重试！', 1);
      }
      setReportResult(result);
    }
  };

  useEffect(() => {
    const { search = '?' } = window.location;

    const query = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    setLoading(true)
    getConsultationReportFetch(query?.identify);
  }, []);

  useUpdateEffect(() => {
    generateWord((blob) => {
      DocxPreview.renderAsync(
        blob,
        document.querySelector('#viewer'),
        null,
        {
          ignoreWidth: false,
          ignoreHeight: false,
          className: 'viewerWrapper'
        },
      )
      .then(() => {
        setLoading(false)
      })
    });
  }, [mappingImageBlobList]);

  const fillImageAndTitleByCanvas = (imageBlob, canvas, title) => {
    return new Promise((resolve) => {
      const url = URL.createObjectURL(imageBlob);
      const titleBgHeight = 80; // 文字背景的高度
      const img = new Image();
      const ctx = canvas.getContext('2d');
      // 清空整个画布
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      img.onload = function () {
        canvas.width = img.width;
        canvas.height = img.height;
        // 绘制图片
        ctx.drawImage(img, 0, 0, img.width, img.height);
        // 绘制文字背景
        ctx.fillStyle = 'rgba(0, 0, 0, 0.5)'; // 设置填充颜色
        ctx.fillRect(0, canvas.height - titleBgHeight, canvas.width, canvas.height); // 绘制矩形
        // 绘制文本
        ctx.font = '50px Arial'; // 文本大小
        const textMetrics = ctx.measureText(title);
        const textWidth = textMetrics.width;
        const textHeight = textMetrics.actualBoundingBoxAscent + textMetrics.actualBoundingBoxDescent;
        const x = (canvas.width - textWidth) / 2;
        ctx.fillStyle = '#fff'; // 文字颜色
        ctx.fillText(title, x, canvas.height - titleBgHeight / 2 + textHeight / 2);
        URL.revokeObjectURL(url);
        canvas.toBlob((blob) => {
          resolve(blob);
        });
      };
      img.src = url;
    });
  };

  useUpdateEffect(() => {
    // 创建Canvas元素
    const canvas = document.createElement('canvas');

    Promise.all(mappingList.map((item) => fillImageAndTitleByCanvas(item.value, canvas, item.title),
    )).then((blobList) => {
      setMappingImageBlobList(blobList);
    });

  }, [mappingList]);

  const commonModule = () => {
    return [
      new Paragraph({
        children: [
          new TextRun({
            text: '苏州美莱',
            size: 26,
          }),
          new TextRun({
            children: generateSpace(88),
            size: 26,
          }),
          new TextRun({
            children: '皮肤检测报告',
            size: 26,
          }),
        ],
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 300,
        },
        border: {
          bottom: {
            color: 'auto',
            space: 1,
            style: 'single',
            size: 6,
          },
        },
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `姓名：${reportResult?.info?.user_name}`,
            size: 26,
          }),
          new TextRun({
            children: generateSpace((77 - Math.ceil(reportResult?.info?.user_name.length * 3.5)) > 0 ? (77 - Math.ceil(reportResult?.info?.user_name.length * 3.5)) : 1),
            size: 26,
          }),
          new TextRun({
            children: `年龄：${reportResult?.info?.user_age}`,
            size: 26,
          }),
        ],
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 300,
        },
      }),
      new Paragraph({
        children: [
          new TextRun({
            text: `手机：${reportResult?.info?.user_mobile}`,
            size: 26,
          }),
          new TextRun({
            children: generateSpace(55),
            size: 26,
          }),
          new TextRun({
            children: `报告日期：${reportResult?.info?.date}`,
            size: 26,
          }),
        ],
        alignment: AlignmentType.LEFT,
        spacing: {
          after: 300,
        },
        border: {
          bottom: {
            color: 'auto',
            space: 1,
            style: 'single',
            size: 6,
          },
        },
      }),
    ];
  };

  const generateWord = async (callback) => {
    const logoInfo = await imageToBlob(reportResult?.info?.organization_icon);

    const doc = new Document({
      sections: [
        {
          children: [
            new Paragraph({
              children: [
                new ImageRun({
                  data: logoInfo.blob,
                  transformation: {
                    width: (logoInfo.width/logoInfo.height)*100,
                    height: 100,
                  },
                }),
              ],
              alignment: AlignmentType.LEFT,
              spacing: {
                before: 100,
                after: 5000,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '皮肤检测报告',
                  size: 50,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 5200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '苏州美莱美容医院',
                  size: 20,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '地址：苏州市阊胥路388号',
                  size: 20,
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 200,
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: '本报告仅作为建议的临床数据参考，不具有病史同等效力，具体的诊断及治疗方案以医生的病历为准。',
                  size: 20,
                }),
                new PageBreak(),
              ],
              alignment: AlignmentType.CENTER,
            }),
            ...commonModule(),
            new Paragraph({
              children: [
                new TextRun({
                  text: '你的肌肤状况',
                  size: 30,
                }),
              ],
              spacing: {
                after: 300,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              spacing: {
                after: 300,
              },
              children: mappingImageBlobList
              .slice(0, 4)
              .map((item) => [
                new ImageRun({
                  data: item,
                  transformation: {
                    width: 150,
                    height: 150,
                  },
                }),
              ])
              .flat(Infinity),
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              spacing: {
                after: 300,
              },
              children: mappingImageBlobList
              .slice(4, 8)
              .map((item) => [
                new ImageRun({
                  data: item,
                  transformation: {
                    width: 150,
                    height: 150,
                  },
                }),
              ])
              .flat(Infinity),
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              spacing: {
                after: 300,
              },
              children: [
                ...mappingImageBlobList
                .slice(8)
                .map((item) => [
                  new ImageRun({
                    data: item,
                    transformation: {
                      width: 150,
                      height: 150,
                    },
                  }),
                ])
                .flat(Infinity),
                new PageBreak(),
              ],
            }),
            ...commonModule(),
            new Paragraph({
              children: [
                new TextRun({
                  size: 30,
                  children: ['诊断结果'],
                }),
              ],
            }),
            ...((reportResult?.diagnosis ?? []).map((item, index) => (
              new Paragraph({
                children: [
                  new TextRun({
                    size: 26,
                    children: `${index + 1}.${item.instance_name}：${item.degree}`,
                  }),
                ],
                spacing: {
                  before: 200,
                },
              })
            ))),
            new Paragraph({
              children: [
                new PageBreak(),
              ],
            }),
            ...commonModule(),
            new Paragraph({
              children: [
                new TextRun({
                  size: 30,
                  children: ['诊断分析'],
                }),
              ],
              spacing: {
                after: 300,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['1.皮肤颜色'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的皮肤颜色：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['I型 总是灼伤、从不晒黑 欧美皮肤人种，皮肤基底层色素含量少'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['II型 总是灼伤、有时晒黑 欧美皮肤人种，皮肤基底层色素含量少'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['III型 有时灼伤、有时晒黑 东南亚黄皮肤人种，皮肤基底层色素含量中等'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['IV型 很少灼伤、经常晒黑 东南亚黄皮肤人种，皮肤基底层色素含量中等'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['V型 从不灼伤、经常晒黑 非洲棕黑色皮肤人种，皮肤基底层色素含量很高'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['VI型 从不灼伤、总是晒黑 非洲棕黑色皮肤人种，皮肤基底层色素含量很高'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['2.皮肤类型'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的皮肤类型：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['中性皮肤：皮肤红润、富有光泽；润滑不沾、无粗糟感；纹理细腻、富有弹性；含水量达20%，PH4.5-6.6，化妆后不易脱妆。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['干性皮肤：缺乏光泽、干涩、弹性差；皮肤薄、皱纹多、纹理较细、易老化、色沉、色斑；干纹、脱皮、色斑、暗沉；含水量达10%，PH 〉6.5，化妆后不易脱妆。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['油性皮肤：皮肤出油多、富有光泽；毛孔粗大、纹理粗、弹性好；易出现痤疮、脂溢性皮炎；含水量达20%，PH〈4.5，或有缺水性油性皮肤，化妆后容易脱妆。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['混合型皮肤：T区-油，V区-干。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['敏感性皮肤：皮肤薄、干燥、红斑；对冷、热、食物、情绪泛红；易随季节性变化，痒感、刺痛、发紧、脱屑、毛细血管扩张等症状。'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['3.皱纹等级'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的皱纹等级：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['额部、眼周、鼻部、面颊、口周、下颌'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['0级：没有皱纹'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['1级：隐约可见纹线'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['2级：浅浅的皱纹'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['3级：中等深度的皱纹'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['4级：较深的皱纹，边缘清晰'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['5级：很深的皱纹，有多余皮肤'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['4.毛孔等级'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的毛孔等级：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['额部、眼周、鼻部、面颊、口周、下颌'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['1分：极少可见毛孔'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['2分：轻微可见毛孔'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['3分：清晰可见毛孔'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['4分：明显可见毛孔或内含小与毛孔大小的角栓'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['5分：非常明显可见毛孔或内含小与毛孔大小一致的角栓'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['6分：明显扩大毛孔或内含突出毛孔的角栓呈草莓样'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['5.痤疮等级'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的痤疮等级：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['1级 轻度，仅有粉刺 '],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['2级 中度，有炎性丘疹'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['3级 中度，出现脓疱'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['4级 重度，有结节囊肿'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['6.色素评估'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的面部色素问题：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['黄褐斑：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['色素型：面部对称生长、呈褐色不规则斑片，边界不清；血管型：色素型基础上基底可见红斑和/或毛细血管扩张。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['雀斑：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['面部散在或密集分布淡褐色针尖至米粒大小的圆型斑点，互不融合，呈对称性分布。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['褐青色痣：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['面部圆形或椭圆形色素斑，呈灰褐色，孤立不融合；'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['太田痣：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['面部蓝黑色或蓝灰色斑片（或者褐青色，淡青色，  黑褐色），颜色呈不规则形（或者网状，地图状），中央色深，边缘渐淡。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['炎症后色素沉着（PIH）：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['擦伤、烧伤、炎症性皮肤病等皮肤炎症愈合之后，该部位出现的褐色斑。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['脂溢性角化：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['面部可见绿豆或黄豆大小的褐色、灰黑色圆形或者卵圆形扁平丘疹或者斑片，边界清楚，表面呈颗粒状，覆以油腻性鳞屑。'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['7.炎症评估'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的面部炎症问题：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['敏感性皮肤：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['面部弥漫性潮红，红斑、毛细血管扩张、伴有干燥，细小鳞屑，灼热、瘙痒及紧绷感。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['玫瑰痤疮：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['Ⅰ级 红斑毛细血管扩张型：面中部出现对称性红斑，可见扩张的毛细血管。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['Ⅱ级 丘疹脓疱型：Ⅰ级的基础上成批发生针头至绿豆大小红色丘疹、脓疱、结节，鼻部、面颊部的毛囊口扩大明显。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['Ⅲ级 增生肥大型（鼻赘型）：鼻部皮脂腺增生肥大及结缔组织增生，形成紫红色结节状凸起，皮肤凹凸不平，毛细血管显著扩张，致使鼻尖、鼻翼肥大，形成鼻赘。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['Ⅳ级 眼型：常与以上三型合并存在。可表现为睑缘炎、结膜炎、虹膜炎、虹膜睫状体炎、前房虹膜炎甚至角膜炎。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['毛细血管扩张症：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['面部红斑，边界不清，压之褪色，可见扩张的毛细血管，呈线状、树枝状、点状分布。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['脂溢性皮炎：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['红斑基础上覆盖黄色油腻性鳞屑，自觉瘙痒明显。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['激素依赖性皮炎：'],
                }),
                new TextRun({
                  size: 22,
                  children: ['皮肤干燥、脱屑、毛细血管扩张、丘疹、脓疱等。自觉刺痛、灼热或肿胀感。'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  bold: true,
                  children: ['8.皮肤老化评估'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的皮肤老化问题：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['眉角下垂、眼角下垂、口角下垂、上睑凹陷、睑颊沟、泪沟、鼻唇沟、木偶纹、颞部凹陷、颊部凹陷'],
                }),
              ],
              spacing: {
                after: 200,
                line: 500,
              },
            }),
            new Paragraph({
              alignment: AlignmentType.LEFT,
              children: [
                new TextRun({
                  size: 22,
                  break: 1,
                  bold: true,
                  children: ['您的美肤建议方案：'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['1、皱纹：静态皱纹可通过美塑治疗、无创注射、激光等方式改善；动态皱纹建议注射肉毒素，效果立竿见影。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['2、毛孔：可通过光子、非剥脱点阵激光、点阵激光、美塑治疗、黄金微针等方式改善。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['3、痤疮：痘痘和痘印可通过光子、美塑治疗、果酸/水杨酸改善，痘坑建议借助黄金微针、点阵激光和无创注射等方式改善。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['4、色素问题：皮秒、调Q、光子等光电仪器祛除色素，联合功效型美塑产品和果酸等加速色素代谢，黄褐斑还需配合口服药内调，抑制黑色素生成。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['5、炎症问题：可通过光子、美塑治疗、595燃料激光等方式改善。'],
                }),
                new TextRun({
                  size: 22,
                  break: 1,
                  children: ['6、皮肤老化：射频（热拉提/热玛吉/热芙美）、超声（超声炮）、激光（Fotona 4D）等光电设备提拉收紧肌肤，同步结合无创注射、线雕等方式改善凹陷和下垂。'],
                }),
              ],
              spacing: {
                after: 220,
                line: 500,
              },
            })
          ],
        },
      ],
    });

    Packer.toBlob(doc)
    .then((blob) => {
      callback(blob);
    });
  };

  const downloadWord = () => {
    generateWord((blob) => {
      saveAs(blob, `皮肤检测报告.docx`);
    });
  };

  return (
    <div className={styles.reportWrapper}>
      {loading && <DotLoading />}
      <div id="viewer"/>
      <Button onClick={downloadWord}>下载</Button>
    </div>
  );
}

export default Index;

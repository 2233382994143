import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tabs } from 'antd-mobile-v5';
import styles from './SymptomTabsItem.less';
import Title from '../../../../../components/Title/Title';
import { isNull } from 'lodash';
import langCommon, { getLangFromLocal } from '../../../../../utils/lang.util';

SymptomTabsItem.propTypes = {
  features: PropTypes.array,
};

function SymptomTabsItem(props){
  const { features } = props;

  const [lang] = useState(getLangFromLocal());

  return(
    <div className={styles.symptomTabsItemWrap}>
      <Tabs>
        {
          features?.map((item) => {

            // result_enable=1 && corrected=0 显示当前degree 如果degree=null 则不显示
            // result_enable=1 && corrected=1 显示当前degree 如果degree=null 则不显示
            // result_enable=0 && corrected=0 不显示
            // result_enable=0 &&  corrected=1 显示当前degree 如果degree=null 则不显示

            let degreeValue = "";
            const {result_enable, degree, corrected } = item;
            if ((result_enable && corrected) || (result_enable && !corrected) || (!result_enable && corrected)) {
              degreeValue = degree ?? "";
            }
            return (
              <Tabs.Tab
                title={`${item.name}${degreeValue}` || ''}
                key={item.id}
                className={styles[`level` + (!isNull(item?.level) ? item?.level : '')]}
              >
                <div className={styles.content}>
                  <img src={item?.detail?.report_image_url} alt=""/>
                  <div className={styles.reason}>
                    <Title showDeco color="#fff">{langCommon.causeAnalysis(lang)}</Title>
                    <div className={styles.detail}>{item?.detail?.reason}</div>
                  </div>
                  <div className={styles.careAdvice}>
                    <Title showDeco color="#fff">{langCommon.nursingSuggestions(lang)}</Title>
                    <div className={styles.detail}>{item?.detail?.care_advice}</div>
                  </div>
                </div>
              </Tabs.Tab>
            )
          })
        }
      </Tabs>
    </div>
  )
}
export default SymptomTabsItem;

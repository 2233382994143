import langJSON from '../../src/language/language.json';
import { intlUtil } from '../utils/intl.util';
import { storageUtil } from '../utils/storage.util';
import { LANG_MAP, LANG_OBJ, LANG_SERVER_MAP } from '../constant';

const defaultLang = ()=>  LANG_OBJ.zh_CN;

export const getLangFromLocal = () => {
  const lang = storageUtil.session.get('lang');
  return lang || getLangByBrowser();
};

// 从浏览器获取语言
export const getLangByBrowser = () => {
  let browserLang = window?.navigator?.language?.toLocaleLowerCase?.();

  const zh_hant_str = ['zh-tw', 'zh-hk', 'zh-mo','zh_Hant_MO'];
  if (zh_hant_str.includes(browserLang)) return LANG_OBJ.zh_HK;
  browserLang = browserLang?.replace?.('-','_')
  const langInfo = Object.values(LANG_MAP)
  .find(({ lang }) => lang.toLocaleLowerCase()
  .includes(browserLang));
  return langInfo?.lang ?? defaultLang();
};
export default intlUtil.proxy(langJSON);

export const getLangByServerLang = (serverLang) => {
  if(!serverLang) return defaultLang();
  const langInfo = Object.entries(LANG_SERVER_MAP)
  .find(([, serverKey]) => serverLang.toLocaleLowerCase() === serverKey.toLocaleLowerCase());
  if (langInfo) return LANG_OBJ[langInfo[0]];
  return defaultLang();
};

import { Toast } from "antd-mobile";
import { LANG_SERVER_MAP } from '../constant';
import langCommon, { getLangFromLocal } from '../utils/lang.util';


const fetchDefaultOptions = {
  method: "GET",
  silent: false, // 静默错误提示
  conversion: null, // 返回结果格式转换
};

const errorMsgMap = {
  500: langCommon.systemError(getLangFromLocal()),
  10001: langCommon.invalidParameter(getLangFromLocal()),
};

export const BaseFetch = (url, { silent, conversion, method } = fetchDefaultOptions) => {
  const lang = getLangFromLocal();
  return new Promise((resolve) => {
    fetch(url, {
      method,
      headers: {
        "Accept-Language": LANG_SERVER_MAP[lang],
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response;
        }
        throw new Error(langCommon.networkException(lang));
      })
      .then(async (res) => {
        const result = await res.json();
        if (result.code !== 0) {
          const msg = errorMsgMap?.[result.code] ?? result?.msg;
          if (!silent && msg) Toast.fail(msg);
          resolve([false, null]);
        }

        if (result.code === 0) {
          // 数据处理
          let resultValue = result.data || {};
          if (typeof conversion === "function") {
            resultValue = conversion(resultValue);
          }
          resolve([true, resultValue]);
        }
      })
      .catch((err) => {
        if (!silent) Toast.fail(langCommon.networkFailed(lang), 1);
        resolve([false, null]);
      });
  });
};


import React, { useEffect, useRef, useState, useCallback } from 'react';
import styles from './consultReport.less';
import Title from '../../../components/Title/Title';
import doctorIcon from '../../../resource/consultReport/doctor.png';
import { Canvas, Chart, Interval, Axis, Tooltip, Legend } from '@antv/f2';
import { BaseUrl } from '../../../service';
import { Toast } from 'antd-mobile';
import DegreeItem from './components/DegreeItem';
import DiagnosisItem from './components/DiagnosisItem';
import FeaturesItem from './components/FeaturesItem';
import Viewer from 'viewerjs';
import 'viewerjs/dist/viewer.min.css';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import * as dayjs from 'dayjs';
import _ from 'lodash';

ConsultReport.displayName = 'ConsultReport';

const GENDER_ENUM = {
  1: '男',
  2: '女',
};

const DEGREE_LEVEL_ENUM = {
  LEVEL1: 1,
  LEVEL2: 2,
  LEVEL3: 3,
  LEVEL4: 4,
};

function ConsultReport() {
  document.title = '咨询报告';

  const [consultReportData, setConsultReportData] = useState({});

  const ImgBoxRef = useRef();

  const getConsultReportData = () => {
    const { search = '?' } = window.location;
    const query = search.substring(1);

    fetch(
      BaseUrl + `/api/consultation/report/render.v3?identify=${query}`,
      {
        method: 'GET',
      },
    )
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error('网络响应异常.');
    })
    .then(async (res) => {
      const result = await res.json();

      if (result.code !== 0) {
        let errorMsg = '';

        switch (result.code) {
          case 500:
            errorMsg = '系统错误！';
            break;
          case 10001:
            errorMsg = '请求参数无效';
            break;
          case 11201:
            errorMsg = '咨询单不存在';
            break;
          default:
            errorMsg = '请求错误！';
        }

        Toast.fail(errorMsg);
      }

      if (result.code === 0) {
        // 数据处理

        const resultValue = result.data || {};

        // 组成图表数据
        const newChartValue =
          resultValue?.meitu &&
          Object.values(resultValue?.meitu).map((item) => ({
            name: item.name,
            score: item.score,
            degree: item.degree,
          }));

        // 组成严重程度列表数据
        const newDegreeValue = resultValue?.meitu &&
          Object.values(resultValue?.meitu).reduce(
            (accumulator, currentValue) => ({
              ...accumulator,
              [currentValue.degree]: [
                ...(accumulator[currentValue.degree] ?? []),
                currentValue,
              ],
            }),
            {},
          );

        setConsultReportData({
          info: resultValue?.info,
          designImages: resultValue?.['3d_design'],
          fromMeituData: resultValue?.meitu,
          chartData: newChartValue,
          diagnosisList: resultValue?.diagnosis,
          degreeLabel: newDegreeValue,
          description: resultValue['3d'],
        });

      }
    })
    .catch((err) => {
      Toast.fail('网络连接失败 !!!', 1);
    });
  };

  const chartInit = () => {
    if (document.getElementById('skinDiagnosisChart')) {
      const context = document.getElementById('skinDiagnosisChart').getContext('2d');
      const Size = Math.round((window.innerWidth / 375) * 14);
      const { props } = <Canvas context={context} pixelRatio={window.devicePixelRatio}>
        <Chart data={consultReportData.chartData}>
          <Legend />
          <Axis field="name" style={{ grid: { opacity: 0 } }} />
          <Axis field="score" style={{ line: { opacity: 0 } }} />
          <Tooltip triggerOn={'touchstart'} nameStyle={{ display: 'none' }} />
          <Interval
            x="name"
            y="score"
            size={Size}
            style={{
              radius: [10, 10, 1, 1],
            }}
            color={{
              field: 'degree',
              // 也可通过回调的方式设置
              callback: (degree) => {
                console.log(degree);
                switch (degree) {
                  case 1:
                    return '#90c418';
                  case 2:
                    return '#0372ff';
                  case 3:
                    return '#f8c822';
                  case 4:
                    return '#fc4850';
                  default:
                    return '#0372ff';
                }
              },
            }}
          />
        </Chart>
      </Canvas>;

      const chart = new Canvas(props);
      chart.render();
    }
  };

  useEffect(() => {
    getConsultReportData();
  }, []);

  useEffect(() => {
    if (!isEmpty(consultReportData.chartData)) {
      chartInit();
    }
  }, [consultReportData.chartData]);

  const handleOnClick = useCallback(
    (e) => {
      if (e.target.nodeName === 'IMG' && e.target.dataset?.preview) {
        const v = new Viewer(e.target, {
          toolbar: false,
          title: false,
          navbar: false,
          hide: () => {
            v.destroy();
          },
        });
        v.show();
      }
    },
    [],
  );

  useEffect(() => {
    if (ImgBoxRef.current) {
      ImgBoxRef.current.addEventListener('click', handleOnClick);
    }
    return () => {
      ImgBoxRef.current?.removeEventListener('click', handleOnClick);
    };
  }, [ImgBoxRef.current]);

  return (
    <div className={styles.consultReportWrapper} ref={ImgBoxRef}>
      <div
        className={classNames(styles.imageWrapper, {
          [styles.bottomPadding]: !isEmpty(consultReportData.fromMeituData),
        })}
      >
        {/*图片区域*/}
        <div className={styles.basisInfoImage}>
          {/*<img className={styles.img} src={consultReportData.info?.channel_icon} alt="" />*/}
          <span
            className={styles.name}
          >
            {consultReportData.info?.channel_name}
          </span>
          <div>{dayjs().format('YYYY')}</div>
          <div>诊断报告</div>
          <div>DIAGNOSE REPORT</div>
          <div>生成时间：{consultReportData.info?.date}</div>
        </div>
        <img className={styles.doctorIcon} src={doctorIcon} alt="" />
      </div>
      {/*基础信息*/}
      <div className={styles.basisInfoWrapper}>
        <div className={styles.basisInfo}>
          <Title showDeco>基础信息</Title>
          <div className={styles.content}>
            <div>姓名：{consultReportData.info?.user_name}</div>
            <div>年龄：{consultReportData.info?.user_age}</div>
            <div>
              性别：{GENDER_ENUM[consultReportData.info?.user_gender]}
            </div>
          </div>
        </div>
      </div>
      {/*皮肤特征诊断*/}
      {consultReportData.fromMeituData && (
        <div className={styles.skinDiagnosisInfoWrapper}>
          <Title showDeco color="#fff">
            皮肤特征诊断
          </Title>
          <div className={styles.canvasWrapper}>
            <canvas id="skinDiagnosisChart"></canvas>
          </div>
          {/*程度问题*/}
          <div className={styles.degreeQuestion}>
            <div className={styles.levelOne}>
              <span>重度问题</span>
              <div>
                {consultReportData.degreeLabel &&
                !consultReportData.degreeLabel[DEGREE_LEVEL_ENUM.LEVEL4] &&
                '无'}
                {consultReportData.degreeLabel
                  ? consultReportData.degreeLabel[
                    DEGREE_LEVEL_ENUM.LEVEL4
                    ]?.map((item) => {
                    return (
                      <div key={item.name}>
                        {item.name} {item.label}
                      </div>
                    );
                  })
                  : '无'}
              </div>
            </div>
            <div className={styles.levelTwo}>
              <span>中度问题</span>
              <div>
                {consultReportData.degreeLabel &&
                !consultReportData.degreeLabel[DEGREE_LEVEL_ENUM.LEVEL3] &&
                '无'}
                {consultReportData.degreeLabel
                  ? consultReportData.degreeLabel[
                    DEGREE_LEVEL_ENUM.LEVEL3
                    ]?.map((item) => {
                    return (
                      <div key={item.name}>
                        {item.name} {item.label}
                      </div>
                    );
                  })
                  : '无'}
              </div>
            </div>
            <div className={styles.levelThree}>
              <span>轻度问题</span>
              <div>
                {consultReportData.degreeLabel &&
                !consultReportData.degreeLabel[DEGREE_LEVEL_ENUM.LEVEL2] &&
                '无'}
                {consultReportData.degreeLabel &&
                consultReportData.degreeLabel[DEGREE_LEVEL_ENUM.LEVEL2]?.map(
                  (item) => {
                    return (
                      <div key={item.name}>
                        {item.name} {item.label}
                      </div>
                    );
                  },
                )}
              </div>
            </div>
            <div className={styles.levelFour}>
              <span>健康</span>
              <div>
                {consultReportData.degreeLabel &&
                !consultReportData.degreeLabel[DEGREE_LEVEL_ENUM.LEVEL1] &&
                '无'}
                {consultReportData.degreeLabel
                  ? consultReportData.degreeLabel[
                    DEGREE_LEVEL_ENUM.LEVEL1
                    ]?.map((item) => {
                    return (
                      <div key={item.name}>
                        {item.name} {item.label}
                      </div>
                    );
                  })
                  : '无'}
              </div>
            </div>
          </div>
        </div>
      )}
      {/*程度详细*/}
      {consultReportData.fromMeituData && (
        <div
          className={styles.degreeDetailWrapper}
        >
          {Object.values(consultReportData.fromMeituData)
          .map((item, index) => (
            <DegreeItem
              key={item.name}
              indexKey={index + 1}
              itemData={item}
            />))}
        </div>
      )}
      {/*形态特征诊断*/}
      {consultReportData?.description && (
        <div className={styles.descriptionlWrapper}>
          <div className={styles.title}>
            <Title showDeco color="#fff">
              形态特征诊断
            </Title>
          </div>
          <div className={styles.descriptionDetailWrapper}>
            {Object.values(consultReportData.description).map((item, index) => (
              <FeaturesItem
                key={item.name}
                featuresData={item}
              />
            ))}
          </div>
        </div>
      )}
      {/*形态模拟*/}
      {consultReportData?.designImages &&
      _.isObject(consultReportData?.designImages) && (
        <div className={styles.formSimulationWrapper}>
          <div className={styles.title}>
            <Title showDeco color="#fff">
              形态模拟
            </Title>
          </div>
          <div className={styles.formSimulation}>
            {/*前*/}
            <div className={styles.after}>
              <div>
                <img
                  data-preview="true"
                  alt="模拟前"
                  src={`${consultReportData.designImages?.ori?.before}`}
                />
                <div className={styles.text}>
                  模拟前 {consultReportData.designImages?.ori?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟前 左45°"
                  src={`${consultReportData.designImages?.['ori_-45']?.before}`}
                />
                <div className={styles.text}>
                  模拟前 {consultReportData.designImages?.['ori_-45']?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟前 左90°"
                  src={`${consultReportData.designImages?.['ori_-90']?.before}`}
                />
                <div className={styles.text}>
                  模拟前 {consultReportData.designImages?.['ori_-90']?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟前 右45°"
                  src={`${consultReportData.designImages?.ori_45?.before}`}
                />
                <div className={styles.text}>
                  模拟前 {consultReportData.designImages?.ori_45?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟前 右90°"
                  src={`${consultReportData.designImages?.ori_90?.before}`}
                />
                <div className={styles.text}>
                  模拟前 {consultReportData.designImages?.ori_90?.name}
                </div>
              </div>
            </div>
            {/*后*/}
            <div className={styles.before}>
              <div>
                <img
                  data-preview="true"
                  alt="模拟后"
                  src={`${consultReportData.designImages?.ori?.after}`}
                />
                <div className={styles.text}>
                  模拟后 {consultReportData.designImages?.ori?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟后 左45°"
                  src={`${consultReportData.designImages?.['ori_-45']?.after}`}
                />
                <div className={styles.text}>
                  模拟后 {consultReportData.designImages?.['ori_-45']?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟后 左90°"
                  src={`${consultReportData.designImages?.['ori_-90']?.after}`}
                />
                <div className={styles.text}>
                  模拟后 {consultReportData.designImages?.['ori_-90']?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟后 右45°"
                  src={`${consultReportData.designImages?.ori_45?.after}`}
                />
                <div className={styles.text}>
                  模拟后 {consultReportData.designImages?.ori_45?.name}
                </div>
              </div>
              <div>
                <img
                  data-preview="true"
                  alt="模拟后 右90°"
                  src={`${consultReportData.designImages?.ori_90?.after}`}
                />
                <div className={styles.text}>
                  模拟后 {consultReportData.designImages?.ori_90?.name}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/*咨询详情*/}
      {consultReportData.diagnosisList && (
        <div className={styles.consultDetailWrapper}>
          <div className={styles.title}>
            <Title showDeco color="#fff">
              咨询详情
            </Title>
          </div>
          <div className={styles.content}>
            <DiagnosisItem diagnosis={consultReportData.diagnosisList} />
          </div>
        </div>
      )}

      {/*footer*/}
      <div className={styles.footer}>
        <span>技术提供方  杭州小肤科技有限公司</span>
      </div>
    </div>
  );
}

export default ConsultReport;

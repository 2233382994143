const storageUtil = {
  local: {
    set(key, item) {
      localStorage.setItem(key, toJSON(item));
    },
    get(key) {
      return toValue(localStorage.getItem(key));
    },
  },
  session: {
    set(key, item) {
      sessionStorage.setItem(key, toJSON(item));
    },
    get(key) {
      return toValue(sessionStorage.getItem(key));
    },
  },
};

function toJSON(item) {
  return JSON.stringify(item);
}

function toValue(json) {
  if (json === 'undefined') {
    return undefined;
  }

  if (json === 'null') {
    return null;
  }

  return JSON.parse(json);
}

export { storageUtil };

import style from './skeletonScreen.less';
import classNames from 'classnames';

function SkeletonScreen() {
  return (
    <div className={style.skeletonBox}>
      <div className={style.skeletonImg}></div>
      <div className={style.skeletonItem}></div>
      <div className={style.skeletonBlock}>
          <div className={style.skeletonBlockHead}></div>
          <div className={style.skeletonBlockBody}>
              <div className={style.skeletonBlockTitle}></div>
              <div className={style.skeletonBlockContent}>
                <div className={style.skeletonBlockItem}></div>
                <div className={style.skeletonBlockItem}></div>
              </div>
          </div>
      </div>
      <div className={style.skeletonItem}></div>
      <div className={style.skeletonItem}></div>
      <div className={classNames(style.skeletonItem,style.skeletonItemlast)}></div>
      <div className={style.skeletonBlock}>
          <div className={style.skeletonBlockHead}></div>
          <div className={style.skeletonBlockBody}>
              <div className={style.skeletonBlockTitle}></div>
              <div className={style.skeletonBlockContent}>
                <div className={style.skeletonBlockItem}></div>
                <div className={style.skeletonBlockItem}></div>
              </div>
          </div>
      </div>
      <div className={style.skeletonItem}></div>
      <div className={style.skeletonItem}></div>
    </div>
  );
}

export default SkeletonScreen;

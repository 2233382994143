import * as THREE from "three";

class StereoEffectHoc {
  static model = {
    upAndDown: "upAndDown",
    leftAndRight: "leftAndRight",
  };
  // model 上下 / 左右
  constructor(renderer, model = StereoEffectHoc.model.leftAndRight) {
    const _stereo = new THREE.StereoCamera();
    _stereo.aspect = model === StereoEffectHoc.model.leftAndRight ? 0.5 : 2;
    const size = new THREE.Vector2();

    this.setEyeSeparation = function (eyeSep) {
      _stereo.eyeSep = eyeSep;
    };

    this.setSize = function (width, height) {
      renderer.setSize(width, height);
    };

    this.render = function (scene, rightScene, camera) {
      rightScene.updateMatrixWorld();

      if (camera.parent === null) camera.updateMatrixWorld();

      _stereo.update(camera);

      renderer.getSize(size);

      if (renderer.autoClear) renderer.clear();
      renderer.setScissorTest(true);
      if (model === StereoEffectHoc.model.leftAndRight) {
        renderer.setScissor(0, 0, size.width / 2, size.height);
        renderer.setViewport(0, 0, size.width / 2, size.height);
        renderer.render(scene, _stereo.cameraL);

        renderer.setScissor(size.width / 2, 0, size.width / 2, size.height);
        renderer.setViewport(size.width / 2, 0, size.width / 2, size.height);
        renderer.render(rightScene, _stereo.cameraR);
      }
      if (model === StereoEffectHoc.model.upAndDown) {
        renderer.setScissor(0, 0, size.width, size.height / 2);
        renderer.setViewport(0, 0, size.width, size.height / 2);
        renderer.render(rightScene, _stereo.cameraL);

        renderer.setScissor(0, size.height / 2, size.width, size.height / 2);
        renderer.setViewport(0, size.height / 2, size.width, size.height / 2);
        renderer.render(scene, _stereo.cameraR);
      }
      renderer.setScissorTest(false);
    };
  }
  static deepClone(object) {
    let target = null;
    if (object.type === "Mesh" || object.type === "Sprite") {
      target = new THREE.Mesh(object.geometry.clone(false), object.material.clone(false));
    } else {
      target = object.clone(false);
    }

    target.name = object.name;
    StereoEffectHoc.copyProperty(target, object);

    if (object.children && object.children.length > 0) {
      object.children.forEach((child) => {
        target.add(StereoEffectHoc.deepClone(child));
      });
    }

    return target;
  }
  static copyProperty(target, source) {
    target.position.x = source.position.x;
    target.position.y = source.position.y;
    target.position.z = source.position.z;

    target.rotation.x = source.rotation.x;
    target.rotation.y = source.rotation.y;
    target.rotation.z = source.rotation.z;

    target.scale.x = source.scale.x;
    target.scale.y = source.scale.y;
    target.scale.z = source.scale.z;
  }
}

export { StereoEffectHoc };

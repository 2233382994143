import React, { useState, useEffect, useCallback } from 'react';
import styles from './skinReport.less';
import SymptomLabel from './components/SymptomLabel/SymptomLabel';
import { Toast } from 'antd-mobile';
import SkeletonScreen from '../../../components/SkeletonScreen/SkeletonScreen';
import AnchorPointItem from './components/AnchorPointItem';
import SkinStatusItem from './components/SkinStatusItem';
import qs from 'qs';
import { getSkinReportData } from '../../../service/skinReport.api';
import { storageUtil } from '../../../utils/storage.util';
import { getLangByBrowser } from '../../../utils/lang.util';
import langCommon from '../../../utils/lang.util';
import { LANG_OBJ } from '../../../constant';

SkinReport.displayName = 'SkinReport';

function SkinReport() {

  const [isSkeletonScreen, setIsSkeleton] = useState(true);
  const [skinReport, setSkinReport] = useState({});
  const [lang, setLang] = useState(LANG_OBJ.zh_CN);

  const getWxConfig = useCallback(() => {
    const url = window.location.href;

    fetch('https://wechat-api.myreal3d.com/api/jssdk/config', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        url,
      }),
    })
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error(langCommon.networkException(lang));
    })
    .then(async (res) => {
      const result = await res.json();

      if (result.code !== 0) {
        let errorMsg = '';

        switch (result.code) {
          case 500:
            errorMsg = langCommon.systemError(lang);
            break;
          case 10001:
            errorMsg = langCommon.invalidParameter(lang);
            break;
          case 11201:
            errorMsg = langCommon.notExistConsultOrder(lang);
            break;
          default:
            errorMsg = langCommon.requestError(lang);
        }

        Toast.fail(errorMsg);
      }

      if (result.code === 0) {
        // 数据处理

        const resultValue = result.data || {};
        const { appId, nonceStr, signature, timestamp } = resultValue?.config ?? {};

        window?.wx?.config?.({
          debug: false, // 开启调试模式,调用的所有 api 的返回值会在客户端 alert 出来，若要查看传入的参数，可以在 pc 端打开，参数信息会通过 log 打出，仅在 pc 端时才会打印。
          appId, // 必填，公众号的唯一标识
          timestamp, // 必填，生成签名的时间戳
          nonceStr, // 必填，生成签名的随机串
          signature, // 必填，签名
          jsApiList: [
            'onMenuShareTimeline',
            'onMenuShareAppMessage',
            'updateAppMessageShareData',
            'onMenuShareTimeline',
          ], // 必填，需要使用的 JS 接口列表
        });

        window?.wx.ready(function () {
          //需在用户可能点击分享按钮前就先调用
          const config = {
            title: langCommon.mySkinReport(lang), // 分享标题
            desc: langCommon.mirage3DTechnicalSupport(lang), // 分享描述
            link: window.location.href, // 分享链接，该链接域名或路径必须与当前页面对应的公众号 JS 安全域名一致
            imgUrl: skinReport.icon, // 分享图标
          };
          window?.wx.updateAppMessageShareData({
            ...config,
          });
          window?.wx.onMenuShareTimeline({
            ...config,
          });
        });
      }
    })
    .catch((err) => {
      console.log('网络连接失败！！！', err)
    });
  }, [skinReport]);

  const getSkinReportDataFetch = async (value) => {
    const [status, result] = await getSkinReportData(value);
    console.log('status',status);
    console.log('result',result);
    if (status) {
      setSkinReport(result);
      setIsSkeleton(false);
    }
  };

  useEffect(() => {
    const localLang = getLangByBrowser();
    // 当前页面保存语言
    setLang(localLang);
    // 存入Storage 其他页面使用
    storageUtil.session.set('lang', localLang);
    document.title = langCommon.mySkinReport(localLang);
  },[]);

  useEffect(() => {
    const { search = '?' } = window.location;

    const query = qs.parse(search, {
      ignoreQueryPrefix: true
    });

    const queryKeys = Object.keys(query);
    let newParams;
    if (queryKeys.includes('hash')) {
      newParams =  query.hash;
    } else {
      newParams = queryKeys[0];
    }
    // 设置标题
    getSkinReportDataFetch(newParams);
  }, []);

  useEffect(() => {
    if (Object.keys(skinReport).length) {
      getWxConfig();
    }
  }, [skinReport]);

  return (
    <>
      {isSkeletonScreen ? (
        <SkeletonScreen />
      ) : (
        <div className={styles.skinReportWrapper}>
          <SymptomLabel data={skinReport} />
          <div>
            {skinReport?.instances && (
              <div>
                <AnchorPointItem skinTabs={skinReport?.instances} />
                <SkinStatusItem data={skinReport} />
              </div>
            )}
          </div>
          <div className={styles.describe}>
            <div>{langCommon.technicalProvider(lang)}</div>
            <div>{langCommon.technology(lang)}</div>
          </div>
        </div>
      )}
    </>
  );
}

export default SkinReport;

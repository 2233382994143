import { unzipSync } from 'fflate';

export const download = ({ url, flag, onProgress, timeout }) => {
  return new Promise((resolve) => {
    let xhr = new XMLHttpRequest();
    xhr.open('get', url);
    xhr.responseType = 'blob';
    let timer;
    if (timeout) {
      timer = setTimeout(() => {
        if (!xhr.status) {
          // 超时后请求还在pending时停止
          xhr.abort();
          resolve({ status: false, flag, code: 0 });
        }
      }, timeout);
    }
    xhr.onprogress = ({ loaded, total }) => {
      onProgress?.({ flag, loaded, total, xhr });
    };
    xhr.onload = () => {
      let blob = xhr.response;
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({ status: true, blob, flag, code: xhr.status });
      }
      resolve({ status: false, flag, code: xhr.status });
    };
    xhr.onerror = () => {
      if (timer) clearTimeout(timer);
      resolve({ status: false, flag, code: xhr.status });
    };
    xhr.send();
  });
};

export const unpackZipByPako = async ({ zipBlob, validFileFormat = ['.obj', '.jpg'] }) => {
  const decompressed = unzipSync(zipBlob, {
    filter(file) {
      return validFileFormat.some((f) => file.name.endsWith(f));
    },
  });

  return Object.entries(decompressed)
  .map(([name, buffer]) => {
    return { [name]: new Blob([buffer]) };
  });
};

export const blobToBase64 = async (fileBlob) => {
  return new Promise((resolve) => {
    if(!fileBlob)  resolve({ status: false });
    const a = new FileReader();
    a.readAsDataURL(fileBlob);
    a.onload = function (e) {
      resolve({ status: true, texture: e.target.result });
    };
    a.onerror = () => {
      resolve({ status: false });
    };
  });
};

export const blobToArrayBuffer = async (fileBlob) => {
  return new Promise((resolve) => {
    const a = new FileReader();
    a.readAsArrayBuffer(fileBlob);
    a.onload = function (e) {
      const buf = new Uint8Array(e.target.result);
      resolve({ status: true, texture: buf });
    };
    a.onerror = () => {
      resolve({ status: false });
    };
  });
};

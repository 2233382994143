import Big from 'big.js';

const priceUtil = {

  format(price, minimumFractionDigits = 2, maximumFractionDigits = 2, isFlag = false) {
    const number = Number(price);

    // 如果传入的不是数字或者没有传入数字的意图
    // 直接返回输入值
    if (price === null || price === '' || Number.isNaN(number)) {
      return price;
    }

    const numberString = (
      new Intl.NumberFormat('en', {
        minimumFractionDigits,
        maximumFractionDigits,
      })
      .format(priceUtil.round(number, 2))
    );

    if (isFlag) {
      return `￥${numberString}`;
    }

    return numberString;
  },

  /**
   * @description 和金额相关的四舍五入算法
   * @param price
   * @param fractionDigits
   * @return {number}
   */
  round(price, fractionDigits = 2) {
    const number = Number(price);

    // 如果传入的不是数字或者没有传入数字的意图
    // 直接返回输入值
    if (price === null || price === '' || Number.isNaN(number)) {
      return price;
    }

    return Number(
      new Big(number)
      .round(fractionDigits + 2, 0)
      .round(fractionDigits, 3),
    );
  },
};

export { priceUtil };

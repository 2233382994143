// 前端语言key
export const LANG_OBJ = {
  zh_CN: 'zh_CN',
  zh_HK: 'zh_HK',
  en_US: 'en_US',
  ja_JP: 'ja_JP',
  ko_KR: 'ko_KR',
};

export const LANG_MAP = {
  [LANG_OBJ.zh_CN]: {
    lang: LANG_OBJ.zh_CN,
    label: '简体中文',
  },
  [LANG_OBJ.zh_HK]: {
    lang: LANG_OBJ.zh_HK,
    label: '繁体中文',
  },
  [LANG_OBJ.en_US]: {
    lang:  LANG_OBJ.en_US,
    label: 'English',
  },
  [LANG_OBJ.ja_JP]: {
    lang: LANG_OBJ.ja_JP,
    label: 'にほんご',
  },
  [LANG_OBJ.ko_KR]: {
    lang: LANG_OBJ.ko_KR,
    label: '한국어',
  },
};

// 第三方包对应的语言包
export const LANG_LIB_MAP = {
  [LANG_OBJ.zh_CN]: {
    antd: 'zh_CN',
    dayjs: 'zh-cn',
  },
  [LANG_OBJ.zh_HK]: {
    antd: 'zh_HK',
    dayjs: 'zh-hk',
  },
  [LANG_OBJ.en_US]: {
    antd: 'en_US',
    dayjs: 'en',
  },
  [LANG_OBJ.ja_JP]: {
    antd: 'ja_JP',
    dayjs: 'ja',
  },
  [LANG_OBJ.ko_KR]: {
    antd: 'ko_KR',
    dayjs: 'ko',
  },
};

// 前端语言key与后端语言key映射
export const LANG_SERVER_MAP = {
  [LANG_OBJ.zh_CN]: 'zh_Hans',
  [LANG_OBJ.zh_HK]: 'zh_Hant',
  [LANG_OBJ.en_US]: 'en',
  [LANG_OBJ.ja_JP]: 'ja',
  [LANG_OBJ.ko_KR]: 'ko',
};

import localforage from "localforage";
import { extendPrototype } from "localforage-setitems";

extendPrototype(localforage);

export const DBInstanceTableName = "TableNameList"; // 仓库下表名称集合，用于获取仓库下所有表信息

// 区分PC/H5
const DBName = "threejsH5";

export const autoDeleteIndexDB = async () => {
  const instanceCountDB = new DB({ storeName: DBInstanceTableName });
  // 包含所有 key 名的数组
  const keys = await instanceCountDB.getKeys();
  // indexDB中存储数据超过10条时删除indexDB
  if (keys.length > 10) return deleteAllDB();
};

export const deleteAllDB = async () => {
  return new Promise((resolve) => {
    localforage
      .dropInstance({ name: DBName })
      .then(() => {
        resolve(true);
      })
      .catch(() => {
        resolve(false);
      });
  });
};

export default class DB {
  #localforageInstance;
  #storeName;

  constructor(options = {}) {
    this.#localforageInstance = localforage.createInstance({
      driver: [localforage.INDEXEDDB, localforage.WEBSQL], // Force WebSQL; same as using setDriver()
      name: DBName,
      version: 1.0,
      storeName: "blobCache", // Should be alphanumeric, with underscores.
      description: "threejs resources blob cache",
      ...options,
    });
    this.#storeName = options.storeName ?? "blobCache";
  }

  async addItem(key, value) {
    const [result] = await this._promiseBack(() => this.#localforageInstance.setItem(key, value));
    return result;
  }

  async addItems(items) {
    const [result] = await this._promiseBack(() => this.#localforageInstance.setItems(items));
    return result;
  }

  async clear() {
    const [result] = await this._promiseBack(() => this.#localforageInstance.clear());
    return result;
  }

  async getItem(key) {
    if (!key) return null;
    const [status, value] = await this._promiseBack(() => this.#localforageInstance.getItem(key));
    return status ? value : null;
  }

  async hasKey(key) {
    const keys = await this.getKeys();
    return keys?.includes(key);
  }

  async checkKeys(keyList) {
    const [status, keys] = await this._promiseBack(() => this.#localforageInstance.keys());
    if (!status) return [];
    const unExist = [];
    keyList.map((k) => {
      const exist = keys?.includes(k);
      if (!exist) unExist.push(k);
    });
    return unExist;
  }

  async getKeys() {
    const [status, keys] = await this._promiseBack(() => this.#localforageInstance.keys());
    return status ? keys : [];
  }

  async dropInstance() {
    const [status, result] = await this._promiseBack(() =>
      this.#localforageInstance.dropInstance()
    );
    return status;
  }

  async deleteItem(key) {
    const [status] = await this._promiseBack(() => this.#localforageInstance.removeItem(key));
    return status;
  }

  _promiseBack(fun) {
    return new Promise((resolve) => {
      fun()
        .then((...result) => {
          resolve([true, ...result]);
        })
        .catch((...params) => {
          resolve([false, params]);
        });
    });
  }
}

import React, { useEffect, useMemo, useState, Fragment } from 'react';
import { Toast } from 'antd-mobile';
import styles from './index.less';
import { Divider, Image, DotLoading } from 'antd-mobile-v5';
import { priceUtil } from '../../../utils/price.util';
import SkeletonScreen from '../../../components/SkeletonScreen/SkeletonScreen';

Index.propTypes = {};

Index.displayName = 'Index';

function Index() {

  const [reportData, setReportData] = useState(null);
  const [loading, setLoading] = useState(true);

  const { search = '?' } = window.location;
  const query = search.substring(1);
  const params = query.endsWith('=') ? query.slice(0, query.length - 1) : query;

  const getReportData = () => {
    fetch(
      `http://m3d-api.zhenmh.com/api/consultation/report/render.v2?hash=${params}`,
      {
        method: 'GET',
      },
    )
    .then(async (response) => {
      if (response.ok) {
        return response;
      }
      throw new Error('网络响应异常.');
    })
    .then(async (res) => {
      const result = await res.json();

      if (result.code !== 0) {
        let errorMsg = '';

        switch (result.code) {
          case 500:
            errorMsg = '系统错误！';
            break;
          case 10001:
            errorMsg = '请求参数无效';
            break;
          case 11201:
            errorMsg = '咨询单不存在';
            break;
          default:
            errorMsg = '请求错误！';
        }

        Toast.fail(errorMsg);
      }

      if (result.code === 0) {
        // 数据处理

        const resultValue = result.data || {};
        setReportData(resultValue);
        setLoading(false);

      }
    })
    .catch((err) => {
      Toast.fail('网络连接失败 !!!', 1);
    });
  };

  const gridRowStyle = (item) => {
    const list = diagnosisMap.get(item.feature_area);

    if (list && list?.length < 2) return {};

    const currentIndex = list.findIndex((l) => l.id === item.id);
    if (currentIndex) {
      return { display: 'none' };
    }
    return {
      gridRowStart: `span ${list.length}`,
    };
  };

  const diagnosisMap = useMemo(() => {
    return (reportData?.diagnosis ?? []).reduce((m, item) => {
      const value = m.get(item.feature_area) ?? [];
      m.set(item.feature_area, [...value, item]);
      return m;
    }, new Map());
  }, [reportData]);

  useEffect(() => {
    getReportData();
  }, []);

  return (
    <>
      {loading ? (
        <SkeletonScreen />
      ) : (
        <div className={styles.zmhReportWrapper}>
          <Image
            src="https://m3d-dev-storage.oss-cn-shanghai.aliyuncs.com/h5/statics/images/head_full.png"
            alt=""
            placeholder={<div className={styles.loadingWrapper}><DotLoading style={{ fontSize: 24 }} color="#E0B4A5" />
            </div>}
          />
          <Image
            src="https://m3d-dev-storage.oss-cn-shanghai.aliyuncs.com/h5/statics/images/history.png"
            alt=""
            placeholder={<div className={styles.loadingWrapper}><DotLoading style={{ fontSize: 24 }} color="#E0B4A5" />
            </div>}
          />

          <div className={styles.infoWrapper}>
            <div className={styles.info}>
              <div className={styles.infoItem}>
                <div className={styles.title}>个人信息</div>
                <div>
                  <Divider />
                </div>
              </div>
              <div className={styles.infoItem}>
                <div>日期：{reportData?.info?.date}</div>
                <div>姓名：{reportData?.info?.user_name}</div>
              </div>
              <div className={styles.infoItem}>
                <div>编号：{reportData?.info?.id}</div>
                <div>年龄：{reportData?.info?.user_age}</div>
              </div>
              <div className={styles.infoItem}>
                <div></div>
                <div>性别：{reportData?.info?.user_gender === 1 ? '男' : reportData?.info?.user_gener === 2 ? '女' : '未填写'}</div>
              </div>
              <div className={styles.infoItem}>
                <div></div>
                <div>电话：{reportData?.info?.user_display_unique}</div>
              </div>
              <div className={styles.infoItem}>
                <div className={styles.label}>面部特征诊</div>
                <div><Divider /></div>
              </div>
            </div>
            <Image
              src="https://m3d-dev-storage.oss-cn-shanghai.aliyuncs.com/h5/statics/images/features.jpg"
              alt=""
              placeholder={<div className={styles.loadingWrapper}><DotLoading style={{ fontSize: 24 }} color="#E0B4A5" />
              </div>}
            />
            <div className={styles.featureWrapper}>
              <div className={styles.featureTitle}>
                <div>问题程度分析图</div>
                <div>
                  <Divider />
                </div>
              </div>
              <div className={styles.featureItem}>
                <div className={styles.featureLabel}>
                  <div></div>
                  <div>轻度</div>
                  <div>中度</div>
                  <div>重度</div>
                </div>
                {reportData?.diagnosis?.map((item) => {
                  const { label_level } = item;
                  return (
                    <div
                      className={styles.featureRow}
                      key={item.id}
                    >
                      <div className={styles.featureName}>{item?.feature_name}</div>
                      {new Array(label_level + 1).fill(1).map((item, index) => {
                        return (
                          <div key={index} className={styles[`level` + index]}></div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {reportData?.skin_images && (
            <div
              className={styles.skinImageWrapper}
            >
              {reportData?.skin_images?.map((item) =>
                <Image
                  key={item.flag}
                  src={item.url}
                  alt=""
                  placeholder={<div className={styles.skinLoadingWrapper}><DotLoading style={{ fontSize: 24 }} color="#E0B4A5" />
                  </div>}
                />)}
            </div>
          )}
          {reportData?.diagnosis?.map((item) => (
            <Image
              key={item.id}
              src={item.feature_image_url}
              alt=""
              placeholder={<div className={styles.loadingWrapper}><DotLoading style={{ fontSize: 24 }} color="#E0B4A5" />
              </div>}
            />
          ))}

          <div className={styles.diagnosisWrapper}>
            {reportData?.diagnosis && (
              <>
                <div className={styles.diagnosisTitle}>诊断方案详情</div>
                <div className={styles.diagnosisContent}>
                  <div className={styles.diagnosisThead}>
                    <div>症状部位</div>
                    <div>症状特征</div>
                    <div>药量</div>
                    {/*<div>单价</div>*/}
                    <div>疗程单价（3次）</div>
                  </div>
                  <div className={styles.diagnosisBody}>
                    {Array.from(diagnosisMap?.values())
                    .flat(Infinity)
                    .map((item) => {
                      return (
                        <Fragment
                          key={item.id}
                        >
                          <div
                            key={item.id}
                            style={gridRowStyle(item)}
                          >
                            {item.feature_area}
                          </div>
                          <div>{item.feature_name}</div>
                          <div>{item.dosage}</div>
                          {/*<div>{item.price}</div>*/}
                          <div
                            style={{
                              borderRight: 'none',
                            }}
                          >
                            {priceUtil.format(item.amount, 0, 2, true)}</div>
                        </Fragment>
                      );
                    })}
                  </div>
                  <div className={styles.diagnosisFooter}>
                    <div>总价</div>
                    <div>
                      {priceUtil.format(
                        reportData?.diagnosis?.map((filterItem) => filterItem.amount).reduce((prev, curr) => Number(prev) + Number(curr))
                        , 0, 2, true,
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            {reportData?.goods_package_suggestion && (
              <>
                <div className={styles.adviceLabel}>建议:</div>
                <div className={styles.goodsTableWrapper}>
                  <div className={styles.goodsThead}>
                    <div></div>
                    {reportData?.goods_package_suggestion[0].price.map((item) => {
                      return (
                        <div key={item.dosage}>{item.dosage}/元</div>
                      );
                    })}
                  </div>
                  {reportData?.goods_package_suggestion?.map((item) => {
                    return (
                      <div
                        key={item.name}
                        className={styles.goodsContent}
                        style={{
                          display: 'grid',
                          gridTemplateColumns: `repeat(auto-fill, ${100 / (reportData?.goods_package_suggestion[0].price.length + 1)}%)`,
                        }}
                      >
                        <div>
                          {item.name}
                        </div>
                        {item.price.map((pItem) => {
                          return <div key={pItem.price}>{priceUtil.format(pItem.price, 0)}</div>;
                        })}
                      </div>
                    );
                  })}
                </div>
              </>
            )}

            <div className={styles.adviceTextWrapper}>
              <div>
                <div><span>* </span>针美汇治疗疗程：</div>
                <div>
                  <div>18-28岁建议做小疗程（3次左右）</div>
                  <div>29-39岁建议做中疗程（6次左右）</div>
                  <div>40岁以上建议做大疗程（9次左右）</div>
                  <div>注：根据个人的面部实际情况和个人对修复程度的要求选择疗程大小</div>
                </div>
              </div>
              <div>
                <div><span>* </span>针美汇维护建议：</div>
                <div>
                  <div>18-28岁每年1-2次维护</div>
                  <div>29-39岁每年2-3次维护</div>
                  <div>40-50岁每年3-4次维护</div>
                </div>
              </div>
            </div>
          </div>

          <Image
            src="https://m3d-dev-storage.oss-cn-shanghai.aliyuncs.com/h5/statics/images/foot_full.png"
            alt=""
            placeholder={<div className={styles.loadingWrapper}><DotLoading style={{ fontSize: 24 }} color="#E0B4A5" />
            </div>}
          />
        </div>
      )}
    </>
  );
}

export default Index;

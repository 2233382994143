import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./degreeItem.less";
import questionIcon from "../../../../resource/consultReport/question.png";
import messageIcon from "../../../../resource/consultReport/message.png";
import CompositeImage from "composite-image";
import { Icon } from 'antd-mobile';

DegreeItem.propTypes = {
  itemData: PropTypes.object,
  indexKey: PropTypes.number,
};

DegreeItem.displayName = "DegreeItem";
function DegreeItem(props) {
  const { indexKey, itemData = {} } = props;

  const [url, setUrl] = useState(null);
  const [loading, setLoading] = useState(true);

  const addThumbnail = (url) => url+ "?imageMogr2/thumbnail/1000x1000";

  useEffect(() => {
    if (!itemData?.images) {
      setLoading(false);
      return;
    }
    const img = new Image();
    img.src = addThumbnail(itemData?.images?.normal);
    img.onload = () => {
      const width = img.width;

      const height = img.height;

      /**
       * @description 生成一张长300 宽400的画布
       * @bgWidth 画布宽
       * @bgHeight 画布高
       **/
      const image = new CompositeImage({
        bgWidth: width, // 合成后图片的宽度
        bgHeight: height, // 合成后图片的高度
      });

      /**
       * @description 第一张图片
       * @src 图片链接
       * @x 相对于画布的x坐标
       * @y 相对于画布的y坐标
       * @width 图片的宽
       * @height 图片的高
       */
      const cfg1 = {
        src: addThumbnail(itemData?.images?.normal),
        x: 0,
        y: 0,
        width,
        height,
      };

      /**
       * @description 第二张图片
       * @src 图片链接
       * @x 相对于画布的x坐标
       * @y 相对于画布的y坐标
       * @width 图片的宽
       * @height 图片的高
       */
      const cfg2 = {
        src: addThumbnail(itemData?.images?.mask),
        x: 0,
        y: 0,
        width,
        height,
      };

      // 合并操作，可支持传入多个图片
      image.composite(cfg1, cfg2).then(() => {
        setUrl(image.print("jpg"));
        setLoading(false);
      });
    };
  }, [itemData]);

  return (
    <div
      className={styles.degreeItemWrapper}
      // ref={ref}
    >
      <div
        className={classNames(
          styles.degreeReportWrapper,
          styles.reportInfo,
          "clearfix"
        )}
      >
        <div className={styles.degreeInfo}>
          <div className={styles.degreeNumber}>0{indexKey}</div>
          <div className={styles.degreeSkinState}>
            <span className={styles.skinState}>{itemData.name}</span>
            <span>{itemData.label}</span>
          </div>
          <div className={styles.basicInfo}>
            <span>正常范围 :</span>
            <span>{itemData.normal_range}</span>
          </div>
        </div>
        <div
          className={styles.degreeImg}
        >
          {url && (
            <img
              className={styles.normalImg}
              data-preview="true"
              src={url}
              alt=""
            />
          )}
          {!url && loading && (
            <Icon type="loading"  />
          )}
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.degreeReportWrapper}>
        <div className={styles.degreeReason}>
          <p className={styles.degreeTitle}>
            <img className={styles.questionIcon} src={questionIcon} alt="" />
            <span className={styles.degreeTitle}>问题原因</span>
          </p>
          <div className={styles.reasonInfo}>
            {itemData?.reason.map((item, idx) => (
              <span key={idx}>
                {Math.fround(item.percentage * 100)}%{item.msg} ;
              </span>
            ))}
          </div>
        </div>
        <div className={styles.degreeSuggest}>
          <p className={styles.degreeTitle}>
            <img className={styles.messageIcon} src={messageIcon} alt="" />
            <span>修复意见</span>
          </p>
          <ul>
            {itemData?.suggest.map((item, idx) => (
              <li key={idx} className={styles.suggestInfo}>
                <span className={styles.suggestTitle}>{item.title} :</span>
                <span>{item.msg}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default DegreeItem;

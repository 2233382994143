import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './historyTime.less';
import { Modal } from 'antd-mobile';
import { Checkbox } from 'antd-mobile-v5';
import classNames from 'classnames';
import * as dayjs from 'dayjs';
import { isFunction } from 'lodash';
import langCommon, { getLangFromLocal } from '../../../../../utils/lang.util';

HistoryTime.propTypes = {};

HistoryTime.displayName = 'HistoryTime';

function HistoryTime(props) {

  const {
    style,
    className,
    time,
    selected,
    disableKey,
    onOk,
    skinList=[],
    showModal = false,
  } = props;

  const [lang] = useState(getLangFromLocal());

  const [visible, setVisible] = useState(false);
  const [selectId, setSelectId] = useState();
  const [checkedValue, setCheckedValue] = useState();


  const resetSelect = useCallback(() => {
    if (selected) {
      setCheckedValue(selected);
    }
  }, [selected]);

  useEffect(() => {
    if (selected) {
      setSelectId(selected[0]);
    }
    resetSelect();

  }, [selected]);

  const selectData = useMemo(() => {
    if (!selected) {
      return null;
    }

    if (skinList && selectId) {
      return skinList.find((item) => item.hash === selectId);
    }
    return null;
  }, [selectId, skinList]);

  return (
    <div className={classNames(styles.historyTimeWrapper, className)} style={{ ...style }}>
      <Modal
        className={styles.modalStyle}
        visible={visible}
        transparent
        maskClosable={false}
        onClose={() => setVisible(false)}
        title={langCommon.selectHistory(lang)}
        footer={[
          { text: langCommon.cancel(lang), onPress: () => setVisible(false) },
          { text: langCommon.confirm(lang), onPress: () => {
            setVisible(false);
            let checkedKey = checkedValue;
            if(Array.isArray(checkedValue)){
              checkedKey = checkedValue.find(c=>c!==disableKey);
              checkedKey = checkedKey??checkedValue.pop()
            }
             isFunction(onOk) && onOk(checkedKey);
            }},
        ]}
      >
        <div className={styles.historyModalWrapper}>
          <Checkbox.Group
            value={checkedValue}
            onChange={(checked) => {
              setCheckedValue(checked.pop());
            }}
          >
            <div className={styles.historyContentWrapper}>
              {skinList?.map((value) => {
                return (
                  <Checkbox
                    key={value.hash}
                    value={value.hash}
                    disabled={disableKey === value.hash}
                  >
                    <img src={value.icon} />
                    <div className={styles.detectionTime}>
                      <span>{dayjs(value?.created_time)
                      .format('YYYY-MM-DD HH:mm')}</span>
                    </div>
                  </Checkbox>
                );
              })}
            </div>
          </Checkbox.Group>
        </div>
      </Modal>
      <div
        className={classNames(styles.historyText, {
          [styles.showBorder]: showModal,
        })}
        onClick={() => {
          if (showModal) setVisible(true);
        }}
      >
        {showModal ? (
          <div className={styles.showModalText}>
            {selectData ? (
              <>
                <div>{dayjs(selectData?.created_time).format('YYYY/MM/DD')}</div>
                <div>{dayjs(selectData?.created_time).format('HH:mm')}</div>
              </>
            ) : langCommon.pleaseSelectText(lang)}
          </div>
        ) : (
          <div>
            {time ? (
              <>
                <div>{dayjs(time).format('YYYY/MM/DD')}</div>
                <div>{dayjs(time).format('HH:mm')} </div>
              </>
            ) : langCommon.pleaseSelectText(lang)}
          </div>
        )}
      </div>
    </div>
  );
}

export default HistoryTime;

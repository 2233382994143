import PropTypes from "prop-types";
import styles from "./featuresItem.less";
import questionIcon from "../../../../resource/consultReport/question.png";
FeaturesItem.propTypes = {
  featuresData: PropTypes.object,
};

FeaturesItem.displayName = "FeaturesItem";

function FeaturesItem(props) {
  const { featuresData = {} } = props;
  return (
    <div className={styles.FeaturesItemWrapper}>
      <div className={styles.FeaturesItemName}>{featuresData?.name}分析</div>
      <div className={styles.FeaturesInfoWrapper}>
        <div className={styles.FeaturesImage}>
          <img
           data-preview="true" 
           src={`${featuresData?.image}`}
            alt=""
          />
        </div>
        <div className={styles.FeaturesRange}>
          <span>正常范围 :</span>
          <span>{featuresData?.normal_range}</span>
        </div>
      </div>
      <div className={styles.line}></div>
      <div className={styles.FeaturesInfoWrapper}>
        <div className={styles.FeaturesInterpret}>
          <img className={styles.questionIcon} src={questionIcon} alt="" />
          <span className={styles.FeaturesTitle}>
            "{featuresData?.name}"解读
          </span>
        </div>
        <div className={styles.FeaturesInterpretInfo}>
          {featuresData?.interpret}
        </div>
      </div>
    </div>
  );
}

export default FeaturesItem;

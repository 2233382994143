import React, { useEffect, useMemo, useState } from 'react';
import styles from './index.less';
import { Tabs, ProgressCircle, ImageViewer, DotLoading, Empty } from 'antd-mobile-v5';
import { getConsultationReport } from '../../../service/mlWordReport.api';
import { Toast } from 'antd-mobile';
import qs from 'qs';
import { isEmpty } from 'lodash';
import {
  download,
  unpackZipByPako,
  blobToArrayBuffer,
} from '@/utils/Threejs/file.js';
import {
  blobToBase64,
} from '@/utils/file.js';
import { DownOutline, UpOutline } from 'antd-mobile-icons';


function Index() {
  document.title = '皮肤检测报告';

  const [reportResult, setReportResult] = useState(null);
  const [mappingList, setMappingList] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isExpansion, setIsExpansion] = useState({});
  const [visible, setVisible] = useState({});
  const [progress, setProgress] = useState(0);

  // 下载资源
  const downloadResource = async (zipUrlMap, dataID, images) => {
    // 下载压缩包
    const zipBlobList = await Promise.all(
      Object.entries(zipUrlMap).map(async ([flag, url]) => {
        const downloadParams = {
          url,
          flag: { id: dataID, flag },
        };
        return await download(downloadParams);
      }),
    );
    // 解压压缩包
    const zipBuffer = await Promise.all(zipBlobList.map(({ blob }) => blobToArrayBuffer(blob)));
    const unpackInfoList = await Promise.all(
      zipBuffer.map(async ({ texture }) => {
        return await unpackZipByPako({
          zipBlob: texture,
        });
      }),
    );
    const filterResult = await Promise.all(unpackInfoList.flat(Infinity).map(async (unpackInfo) => {
      const [key, blob] = Object.entries(unpackInfo).pop();
      if (key.endsWith('M.jpg')) return;
      const title = images.find((findItem) => findItem.filename.includes(key))?.title;
      if (isEmpty(title)) return;
      const url = await blobToBase64(blob);
      return { key: key, value: url, title: title };

    }));

    setMappingList(filterResult.filter(Boolean));

    return true;
  };

  const getConsultationReportFetch = async (value) => {
    setLoading(true);
    const intervalId = fakeProgress();
    const [status, result] = await getConsultationReport(value);
    if (status) {
      const { zips } = result?.skin ?? {};
      if (zips) {
        const urlMap = {
          [zips?.thumb?.flag]: [zips?.thumb?.url],
        };

        let resourcesStatus = true;
        resourcesStatus = await downloadResource(urlMap, result?.info?.id, result?.skin?.images);
        if (!resourcesStatus) {
          Toast.fail('数据下载失败！请刷新后重试！', 1);
          setProgress(0);
          clearInterval(intervalId);
        }
      }
      setLoading(false);
      setProgress(100);
      setReportResult(result);
    }
    if (!status) {
      setProgress(0);
      clearInterval(intervalId);
    }
  };

  const fakeProgress = (stop) => {
    let progress = 0;
    const intervalId = setInterval(() => {
      progress += Math.random() * 10;
      if (progress >= 100 || stop) {
        clearInterval(intervalId);
      } else {
        setProgress(Math.floor(progress))
      }
    }, 500);
    return intervalId;
  }

  useEffect(() => {
    const { search = '?' } = window.location;

    const query = qs.parse(search, {
      ignoreQueryPrefix: true,
    });
    getConsultationReportFetch(query?.identify);
  }, []);



  const skinStatusMemo = useMemo(() => {
    if (isEmpty(mappingList)) return (<Empty
      style={{ paddingTop: '50%' }}
      description='暂无数据'
    />)
    return (
      <div className={styles.skinStatusWrapper}>
        {mappingList?.map((item) => {
          const { value, title, key } = item;
          return (
            <div key={key} className={styles.imgWrapper}>
              <ImageViewer
                image={value.texture}
                visible={visible[key]?true:false}
                onClose={() => setVisible((prevState) => ({
                  ...prevState,
                  [key]: false,
                }))}
              />
              <img
                className={styles.imgPreview}
                src={value.texture}
                onClick={() => setVisible((prevState) => ({
                  ...prevState,
                  [key]: !prevState[key],
                }))}
              />
              <div className={styles.title}>{title}</div>
            </div>);
        })}
      </div>
    );
  }, [mappingList, visible]);

  const diagnosisResultMemo = useMemo(() => {
    return (
      <div className={styles.diagnosisWrapper}>
        {reportResult?.diagnosis?.map((item) => {
          return (
            <div key={item.instance_id} className={styles.diagnosisItemWrapper}>
              <div className={styles.instanceName}>{item.instance_name}</div>
              <div>{item.attitudes?.map((attItem) => {
                return (
                  <div>
                    {attItem.feature_name}：<span>{attItem.label_degree}</span>
                  </div>
                );
              })}</div>
              <div
                onClick={() => setIsExpansion((prevState) => ({
                  ...prevState,
                  [item.instance_id]: !prevState[item.instance_id],
                }))}
              >
                {isExpansion[item.instance_id] ? '收起' : '展开'}详情
                {isExpansion[item.instance_id] ? <UpOutline className={styles.downIcon} /> :
                  <DownOutline className={styles.downIcon} />
                }
              </div>
              <div style={{ height: isExpansion[item.instance_id] ? 'auto' : '0', overflow: 'hidden' }}>
                {item?.detail?.degree_comments?.map((detailItem) => <div>
                  {!(detailItem.degree === '有' || detailItem.degree === '无') && <span>{detailItem.degree}：</span>}{detailItem.comment}</div>)}
              </div>
              <div></div>
            </div>
          );
        })}
      </div>
    );
  }, [reportResult, isExpansion]);

  const skinSuggestMemo = useMemo(() => {
    return (
      <div className={styles.skinSuggestWrapper}>
        {reportResult?.diagnosis?.map((item) => {
          if (isEmpty(item.detail.describe)) return
          return (
            <div>
              <div>{item.instance_name}</div>
              <div>{item.detail.describe}</div>
            </div>
          );
        })}
      </div>
    );
  }, [reportResult]);

  return (
    <>
      {loading ? <div style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
      }}>
        <div>
          <ProgressCircle
            percent={progress}
            style={{
              '--fill-color': '#47B4BB',
              '--size': `${window.screen.width > 400 ? '100px': '50px'}`,
            }}
          >{progress}%</ProgressCircle>
        </div>
        <div style={{marginTop: '0.1rem'}}>数据加载中<DotLoading color="#333333"/></div>
      </div> : (
        <div className={styles.meilaiReportWrapper}>
          <div className={styles.prompt}>
            <p>提示：本报告仅作为建议的临床数据参考，不具有病史同等效力，具体的诊断及治疗方案以医生的病历为准。</p>
          </div>
          <div className={styles.userInfo}>
            <div>
              <p>姓名：{reportResult?.info?.user_name}</p>
              <p>手机：{reportResult?.info?.user_mobile}</p>
            </div>
            <div>
              <p>生日：{reportResult?.info?.user_birthday}</p>
              <p>报告日期：{reportResult?.info?.date}</p>
            </div>
          </div>
          <div className={styles.contentWrapper}>
            <Tabs
              style={{
                '--fixed-active-line-width': '0.2rem',
                '--active-line-color': '#47B4BB',
              }}
            >
              <Tabs.TabPane
                title="肌肤状况"
                key={1}
              >
                {skinStatusMemo}
              </Tabs.TabPane>
              <Tabs.TabPane
                title="诊断结果及分析"
                key={2}
              >
                {diagnosisResultMemo}
              </Tabs.TabPane>
              <Tabs.TabPane
                title="美肤建议方案"
                key={3}
              >
                {skinSuggestMemo}
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
}

Index.displayName = 'Index';

export default Index;

import { BaseUrl } from './index';
import { BaseFetch } from './baseService';

// 请求3D信息
export const getSkinReportData = async (hash, options) => {
  return await BaseFetch(BaseUrl + `/api/skin/report/render.v2?hash=${hash}`, options);
};

export const getSkinList = async (hash, options) => {
  return await BaseFetch(BaseUrl + `/api/skin/report/history?hash=${hash}`, options);
};

import JSZip from "jszip";

export const download = ({ url, flag, onProgress }) => {
  return new Promise((resolve) => {
    let xhr = new XMLHttpRequest();
    xhr.open("get", url);
    xhr.responseType = "blob";
    xhr.onprogress = ({ loaded, total }) => {
      onProgress?.({ flag, loaded, total, xhr });
    };
    xhr.onload = () => {
      let blob = xhr.response;
      if (xhr.status >= 200 && xhr.status < 300) {
        resolve({ status: true, blob, flag });
      }
      resolve({ status: false, flag });
    };
    xhr.onerror = () => {
      resolve({ status: false, flag });
    };
    xhr.send();
  });
};

export const unpackZip = async ({ zipBlob, validFileFormat = [".obj", ".jpg"] }) => {
  const zip = new JSZip();
  const zipInfo = await zip.loadAsync(zipBlob);
  const validFiles = Object.keys(zipInfo.files).filter((name) =>
    validFileFormat.some((f) => name.endsWith(f))
  );
  return await Promise.all(
    validFiles.map(async (name) => {
      const unpackInfo = await zipInfo.file(name).async("blob");
      // const base64Info = await blobToBase64(unpackInfo);
      return { [name]: unpackInfo };
      // return { [name]: base64Info?.status ? base64Info.texture : null };
    })
  );
};

export const blobToBase64 = async (fileBlob) => {
  return new Promise((resolve) => {
    var a = new FileReader();
    a.readAsDataURL(fileBlob);
    a.onload = function (e) {
      resolve({ status: true, texture: e.target.result });
    };
    a.onerror = () => {
      resolve({ status: false });
    };
  });
};

export const imageToBlob =  (imageUrl) => {
  return new Promise((resolve)=>{
  const img = new Image();
  img.crossOrigin = "Anonymous";

  img.onload = function() {
    // 创建Canvas元素
    const canvas = document.createElement("canvas");
    const width = this.width;
    const height = this.height;
    canvas.width = width;
    canvas.height = height;

    // 将图像渲染到Canvas上下文中
    const ctx = canvas.getContext("2d");
    ctx.drawImage(this, 0, 0);

    // 将Canvas转换为Blob对象
    canvas.toBlob(function(blob) {
      resolve( {blob, width,height})
    }, "image/png", 1);
  };
  img.src = imageUrl;
  })
}



import localforage from "localforage";

export default class DB {
  #localforageInstance;

  constructor(options = {}) {
    this.#localforageInstance = localforage.createInstance({
      driver: [localforage.INDEXEDDB, localforage.WEBSQL], // Force WebSQL; same as using setDriver()
      name: "threejs",
      version: 1.0,
      storeName: "blobCache", // Should be alphanumeric, with underscores.
      description: "threejs resources blob cache",
      ...options,
    });
  }
  async addItem(key, value) {
    const [result] = await this._promiseBack(() => this.#localforageInstance.setItem(key, value));
    return result;
  }
  async clear() {
    const [result] = await this._promiseBack(this.#localforageInstance.clear);
    return result;
  }

  async getItem(key) {
    if (!key) return null;
    const [status, value] = await this._promiseBack(() => this.#localforageInstance.getItem(key));
    return status ? value : null;
  }
  async hasKey(key) {
    const [status, keys] = await this._promiseBack(() => this.#localforageInstance.keys());
    if (!status) return false;
    return keys?.includes(key);
  }

  _promiseBack(fun) {
    return new Promise((resolve) => {
      fun()
        .then((...result) => {
          resolve([true, ...result]);
        })
        .catch((...params) => {
          resolve([false, params]);
        });
    });
  }
}

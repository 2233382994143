import React from "react";
import PropTypes from "prop-types";
import styles from "./SkinStatusItem.less";
import CheckState from "./CheckState";
import SymptomTabsItem from "./SymptomTabsItem/SymptomTabsItem";

SkinStatusItem.displayName = "SkinStatusItem";

SkinStatusItem.propTypes = {
  data: PropTypes.object,
};

function SkinStatusItem(props) {
  const {
    data: { images, instances, icon, zips },
  } = props;

  return (
    <div className={styles.skinStatusItemWrap}>
      {instances?.map((item) => {
        return (
          <div id={item.name} key={item.id} className={styles.skinStatusItemContent}>
            <CheckState
              images={images}
              label={item?.name}
              flag={images[0]?.flag}
              url={icon}
              downloadUrl={zips?.thumb?.url}
              degree={item?.degree}
              level={item?.level}
              description={item?.describe}
              resultEnable={item?.features[0]?.result_enable}
            />
            <SymptomTabsItem features={item?.features} />
          </div>
        );
      })}
    </div>
  );
}
export default SkinStatusItem;

import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './AnchorPointItem.less';
import classNames from 'classnames';

AnchorPointItem.displayName = 'AnchorPointItem';

AnchorPointItem.propTypes = {
  skinTabs: PropTypes.array,
};

function AnchorPointItem(props) {
  const { skinTabs } = props;
  const [skin, setSkin] = useState(skinTabs[0]?.name);
  const [needFixed, setNeedFixed] = useState(false);
  const [isScroll, setIsScroll] = useState(false);

  const ulRef = useRef();

  useEffect(() => {
    window.onscroll = () => {
      const tabs = document.getElementById('tabs');
      if (tabs) {
        const fixedTop = tabs.offsetTop;
        let scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);

        skinTabs.map((item) => {
          const skinTabsTop = document.getElementById(item.name).offsetTop - 10;
          if (scrollTop >= skinTabsTop) {
            setSkin(item.name);
          }
          return item;
        });

        if (scrollTop >= fixedTop) {
          !needFixed && setNeedFixed(true);
        } else if (scrollTop < fixedTop) {
          needFixed && setNeedFixed(false);
        }
      }
    };
  });

  useEffect(() => {
    if (ulRef.current.clientWidth < ulRef.current.scrollWidth) {
      setIsScroll(true);
    }
  },[])

  return (
    <div className={styles.AnchorPointItemWrap} id="tabs">
      <ul
        ref={ulRef}
        className={classNames(styles.AnchorPointList,{
          [styles.AnchorPointListFixed]: needFixed,
          [styles.longElement]: isScroll,
        })}
      >
        {skinTabs.map((item) => {
          return (
            <li
              key={item.name}
              className={classNames({
                [styles.active] : item.name === skin,
              })}>
              <a href={'#' + item.name} onClick={() => setSkin(item.name)}>{item.name}</a>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default AnchorPointItem;

import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './diagnosisItem.less';

DiagnosisItem.propTypes = {
  diagnosis: PropTypes.array
}
function DiagnosisItem(props) {
  const {
    diagnosis = [],
  } = props;

  return (
    <ul 
      className={styles.diagnosisWrapper}
    >
      {
        diagnosis && diagnosis.map((item, idx) => (
          <li 
            key={idx} 
            className={styles.diagnosis}
          >
            <div 
              className={styles.diagnosisInstance}
            >
              <span>
                品项 :
              </span>
              <span>
                {item.instance_name}
              </span>
            </div>
            <ul 
              className={
                classNames(styles.diagnosisList, styles.diagnosisTitle)
              }
            >
             <li>相貌特征</li>
             <li>相貌特征程度</li>
             <li>设计方案</li>
             <li>医疗方案</li>
             <li>产品</li>
            </ul>
            <div className={styles.diagnosisItem}>
              {
                item.attitudes.map((v, index) => (
                  <ul key={index} 
                    className={
                      classNames(styles.diagnosisList, styles.diagnosisName)
                    }
                  >
                    <li>{v.feature_name}</li>
                    <li>{v.label_name}</li>
                    <li>{v.solution_name}</li>
                    <li>{v.operation_name}</li>
                    <li>{v.goods_name}</li>
                  </ul>
                ))
              }
            </div>
          </li>
        ))
      }
    </ul>
  )
}

export default DiagnosisItem;
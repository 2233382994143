import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import styles from './symptomLabel.less';
import LabelItem from '../LabelItem/LabelItem';
import { Link } from 'react-router-dom';
import { Divider } from 'antd-mobile-v5';
// import RadarChart from "../RadarChart/RadarChart";
// import drop from "../../../../../resource/skinReport/drop.png";
import history from '../../../../../resource/skinReport/history.png';
import langCommon, { getLangFromLocal } from '../../../../../utils/lang.util';

SymptomLabel.propTypes = {
  data: PropTypes.object,
};

SymptomLabel.displayName = 'SymptomLabel';

function SymptomLabel(props) {
  const { data } = props;

  const [symptom, setSymptom] = useState(data);
  const [lang] = useState(getLangFromLocal());

  const getHash = () => {
    const { search = '?' } = window.location;
    const query = search.substring(1);

    const params = query.endsWith('=') ? query.slice(0, query.length - 1) : query;
    return params;
  };

  const instancesMemo = useMemo(() => {
      let filterInstances = symptom.instances.slice();
      filterInstances.sort((a, b) => b.level - a.level);
      return filterInstances;
  },[symptom.instances])

  useEffect(() => {
    setSymptom(data);
  }, [data]);

  return (
    <div className={styles.symptomLabelWrapper}>
      <div className={styles.symptomLabelArea}>
        <div className={styles.leftLabel}>
          {instancesMemo.slice(0, 3).map((item, index) => {
            return <LabelItem key={index} item={item?.name} level={item?.level} />;
          })}
        </div>
        <div className={styles.middleWrapper}>
          <div className={styles.describeText}>DIAGNOSE REPORT</div>
          <div className={styles.date}>{symptom?.date}</div>
          <Link
            to={{
              pathname: `/my3DImage/${getHash()}`,
              state: { data: symptom, isRawDataNull: true },
            }}
          >
            <img src={symptom?.icon} alt="" />
            <div className={styles.icon}>3D</div>
          </Link>
        </div>
        <div className={styles.rightLabel}>
          {instancesMemo.slice(3,6).map((item, index) => {
            return <LabelItem key={index} item={item?.name} level={item?.level} />;
          })}
        </div>
      </div>
      <div className={styles.checkStateDegree}>
        <div>{langCommon.severe(lang)}</div>
        <div>{langCommon.moderate(lang)}</div>
        <div>{langCommon.mild(lang)}</div>
        <div>{langCommon.healthy(lang)}</div>
      </div>
      <div className={styles.divider}>
        <Divider>{langCommon.fullFace(lang)}</Divider>
      </div>
      <div className={styles.skinTypesArea}>
        <div className={styles.skinTypesItem}>
          <img src={history} alt="" />
          <div className={styles.skinTypesContent}>
            <div>{symptom?.types?.aging_degree.label}</div>
            <div>{symptom?.types?.aging_degree.type}</div>
          </div>
        </div>
      </div>
      {/*<RadarChart data={symptom.instances}/>*/}
    </div>
  );
}

export default SymptomLabel;

import React from 'react';
import PropTypes from 'prop-types';
import styles from './labelItem.less';
import classNames from 'classnames';

LabelItem.propTypes = {
  item: PropTypes.string,
  style: PropTypes.string,
};

LabelItem.displayName = 'LabelItem';

function LabelItem(props) {
  const { item, style, level } = props;
  return (
    <div
      className={classNames(styles.LabelItemWrapper, style)}
    >
      <span className={classNames(styles.content, styles[`level` + level])}>
        {item}
      </span>
    </div>
  );
}

export default LabelItem;
